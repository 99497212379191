import MessageFormat from 'messageformat';
import type { Formatter } from 'vue-i18n';

export class CustomFormatter implements Formatter {
    private locale: string;
    private formatter: MessageFormat;
    private caches: { [key: string]: (args: object) => string };

    constructor(locale = 'en') {
        this.locale = locale;
        this.formatter = new MessageFormat(this.locale, {
            customFormatters: {
                /* Usado para formatear partes del título de pestañas */
                titlepart: (v) => (v ? `${v} • ` : ''),
            },
        });
        this.caches = Object.create(null);
    }

    public interpolate(message: string, values: any) {
        let withVNodes = false;
        const vNodeKeys: string[] = [];

        // Hacemos una copia de los valores para no modificar el objeto original
        const formatterValues = values && typeof values === 'object' ? { ...values } : values;

        // Si en los valores hay alguna clave con un valor de tipo array (suponemos que contiene VNode)
        // la sustituimos por un placeholder y la guardamos para después sustituirla por el VNode
        if (formatterValues && typeof formatterValues === 'object') {
            for (const [key, value] of Object.entries(formatterValues)) {
                if (Array.isArray(value)) {
                    withVNodes = true;
                    formatterValues[key] = `{${key}}`;
                    vNodeKeys.push(key);
                }
            }
        }

        let fn = this.caches[message];
        if (!fn) {
            fn = this.formatter.compile(message, this.locale);
            this.caches[message] = fn;
        }

        // Se formatea el mensaje con los valores sin VNodes
        const formattedMessage = fn(formatterValues ?? {});

        // Si no hay VNodes, se devuelve el mensaje formateado
        if (!withVNodes) {
            return [formattedMessage];
        }
        const result: any[] = [];

        let lastIdx = 0;
        // Se recorren las claves de los valores que contienen VNodes
        for (const key of vNodeKeys) {
            const idx = formattedMessage.indexOf(`{${key}}`);

            // Si no se encuentra la clave en el mensaje formateado, se pasa a la siguiente clave
            if (idx === -1) {
                continue;
            }

            // Se añade al resultado la parte del mensaje anterior a la clave
            result.push(formattedMessage.slice(lastIdx, idx));
            // Se añade al resultado el array de VNode
            result.push(values[key]);

            lastIdx = idx + 2 + key.length;
        }

        // Se añade al resultado la parte del mensaje restante
        if (lastIdx < formattedMessage.length) {
            result.push(formattedMessage.slice(lastIdx));
        }

        return result;
    }
}
