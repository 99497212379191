import Vue from 'vue';
import feature from 'vue-feature-toggle';

if (process.env.NODE_ENV === 'development') {
    // Comprobar si es IE. No fucionan los logs con la consola cerrada
    if (!(window.document as any).documentMode) {
        feature.showLogs();
    }
}

const CUSCUS_DASHBOARD_FEATURE = 'cuscus-dashboard';
const CELLA_FEATURE = 'cella';
const INVITE_USERS_FEATURE = 'invite-users';
const CHARACTERISTIC_LIST = 'characteristic-list';

feature.visibility(CUSCUS_DASHBOARD_FEATURE, !!window.coquus.cuscusDashboardUri);
feature.visibility(CELLA_FEATURE, !!window.coquus.cellaUri);
feature.visibility(INVITE_USERS_FEATURE, window.coquus.inviteUsers);
feature.visibility(CHARACTERISTIC_LIST, window.coquus.characteristicListEnabled);

export function isCuscusDashboardAvailable(): boolean {
    return feature.isVisible(CUSCUS_DASHBOARD_FEATURE);
}

export function isCellaAvailable(): boolean {
    return feature.isVisible(CELLA_FEATURE);
}

export function isInviteUsersAvailable(): boolean {
    return feature.isVisible(INVITE_USERS_FEATURE);
}

export function isCharacteristicListEnabled(): boolean {
    return feature.isVisible(CHARACTERISTIC_LIST);
}

Vue.component('Feature', feature);
