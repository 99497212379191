import { h } from 'vue';
import { RouteConfig, Route, NextFunc } from 'vue-router';
import {
    Estadisticas_AsignacionesExtras_Acceso,
    Estadisticas_Pactos_Acceso,
    Estadisticas_Pensiones_Acceso,
    Estadisticas_PreciosPlatos_Acceso,
    Comensales_History_Acceso,
    Estadisticas_Produccion_Acceso,
    KioskStats_Read,
} from '../auth/permissions';

const Pensiones = () => import(/* webpackChunkName: "consultas-pensiones" */ './pensiones/index.vue');
const AsignacionExtras = () => import(/* webpackChunkName: "consultas-asignacion-extras" */ './asignacion-extras/index.vue');
const PreciosPlatos = () => import(/* webpackChunkName: "consultas-precios-platos" */ './precios-platos/index.vue');
const PactoConsumos = () => import(/* webpackChunkName: "consultas-pacto-consumos" */ './pacto-consumos/index.vue');
const ConsultasProduccion = () => import(/* webpackChunkName: "consultas-produccion" */ './produccion/index.vue');
const History = () => import(/* webpackChunkName: "consultas-history" */ './history/index.vue');
const HistoryComensales = () => import(/* webpackChunkName: "consultas-history" */ './history/comensales/list/index.vue');
const HistoryComensalDetail = () => import(/* webpackChunkName: "consultas-history" */ './history/comensales/detail/index.vue');
const HistoryComidas = () => import(/* webpackChunkName: "consultas-history" */ './history/comidas/list/index.vue');
const KioskStats = () => import(/* webpackChunkName: "consultas-kiosk-stats" */ './kiosk-stats/index.vue');

async function beforeEnter(to: Route, from: Route, next: NextFunc) {
    await import(/* webpackChunkName: "consultas" */ './store');
    next();
}

export default [
    {
        path: 'estadisticas/pensiones',
        name: 'home.estadisticas.pensiones',
        component: Pensiones,
        beforeEnter,
        meta: {
            permissions: [Estadisticas_Pensiones_Acceso],
            title: 'pensiones',
        },
    },
    {
        path: 'estadisticas/asignacion-extras',
        name: 'home.estadisticas.asignacion-extras',
        component: AsignacionExtras,
        beforeEnter,
        meta: {
            permissions: [Estadisticas_AsignacionesExtras_Acceso],
            title: 'asignacionExtras',
        },
    },
    {
        path: 'estadisticas/precios-platos',
        name: 'home.estadisticas.precios-platos',
        component: PreciosPlatos,
        beforeEnter,
        meta: {
            permissions: [Estadisticas_PreciosPlatos_Acceso],
            title: 'preciosPlatos',
        },
    },
    {
        path: 'estadisticas/pacto-consumos',
        name: 'home.estadisticas.pacto-consumos',
        component: PactoConsumos,
        beforeEnter,
        meta: {
            permissions: [Estadisticas_Pactos_Acceso],
            title: 'pactos',
        },
    },
    {
        path: 'estadisticas/produccion',
        name: 'home.estadisticas.produccion',
        component: ConsultasProduccion,
        beforeEnter,
        meta: {
            permissions: [Estadisticas_Produccion_Acceso],
            title: 'produccion',
        },
    },
    {
        path: 'history',
        name: 'home.history',
        redirect: { name: 'home.history.comidas' },
        component: { render: () => h('router-view') },
        beforeEnter,
        meta: {
            permissions: [Comensales_History_Acceso],
            title: 'history',
        },
        children: [
            {
                path: '',
                redirect: { name: 'home.history.comidas' },
                component: History,
                children: [
                    {
                        path: 'comensales',
                        name: 'home.history.comensales',
                        component: HistoryComensales,
                    },
                    {
                        path: 'comidas',
                        name: 'home.history.comidas',
                        component: HistoryComidas,
                    },
                ],
            },
            {
                path: 'comensales/:comensalId',
                name: 'home.history.comensales.detail',
                component: HistoryComensalDetail,
                props: (route) => ({ comensalId: Number.parseInt(route.params.comensalId, 10) }),
            },
        ],
    },
    {
        path: 'estadisticas/kiosk-stats',
        name: 'home.consultas.kiosk-stats',
        component: KioskStats,
        meta: {
            permissions: [KioskStats_Read],
            title: 'kioskStats.title',
        },
    },
] as RouteConfig[];
