import { MutationTree } from 'vuex';

import { HomeState } from './index';
import {
    SetFiltersForReposiciones,
    SetFiltersForComensales,
    SetFiltersForArticulos,
    SetFiltersForHistory,
    SetFiltersForPlatos,
    SetFiltersForMenus,
    SetFiltersForPactos,
    SetFiltersForComplejos,
    SetFiltersForCocinas,
    SetFiltersForDietas,
    SetFiltersForPreparaciones,
} from './mutation-payloads';

export default {
    setFiltersForComensales(state, payload: SetFiltersForComensales) {
        state.comensalesFilters.centroId = payload.centroId;
        state.comensalesFilters.servicioId = payload.servicioId;
    },
    setFiltersForReposiciones(state, payload: SetFiltersForReposiciones) {
        state.reposicionesFilters.selectedCentro = payload.centro;
        state.reposicionesFilters.selectedServicio = payload.servicio;
        state.reposicionesFilters.selectedDate = payload.date;
        state.reposicionesFilters.pendientes = payload.pendientes;
        state.reposicionesFilters.conSolicitud = payload.conSolicitud;
    },
    setFiltersForArticulos(state, payload: SetFiltersForArticulos) {
        state.articulosFilters.cocinaId = payload.cocinaId;
        state.articulosFilters.tipo = payload.tipo;
        state.articulosFilters.soloActivos = payload.soloActivos;
        state.articulosFilters.filtro = payload.filtro;
    },
    setFiltersForPlatos(state, payload: SetFiltersForPlatos) {
        state.platosFilters.cocinaId = payload.cocinaId;
        state.platosFilters.selectedDietaId = payload.selectedDietaId;
        state.platosFilters.soloActivos = payload.soloActivos;
        state.platosFilters.filtro = payload.filtro;
    },
    setFiltersForHistory(state, payload: SetFiltersForHistory) {
        state.historyFilters.desde = payload.desde;
        state.historyFilters.hasta = payload.hasta;

        if (payload.identificacion !== undefined) {
            state.historyFilters.identificacion = payload.identificacion;
        }

        if (payload.centros !== undefined) {
            state.historyFilters.centros = payload.centros;
        }

        if (payload.dieta !== undefined) {
            state.historyFilters.dieta = payload.dieta;
        }

        if (payload.ingesta !== undefined) {
            state.historyFilters.ingesta = payload.ingesta;
        }

        if (payload.platos !== undefined) {
            state.historyFilters.platos = payload.platos;
        }

        if (payload.conAcciones !== undefined) {
            state.historyFilters.conAcciones = payload.conAcciones;
        }

        if (payload.withLateRequests !== undefined) {
            state.historyFilters.withLateRequests = payload.withLateRequests;
        }

        if (payload.withGuests !== undefined) {
            state.historyFilters.withGuests = payload.withGuests;
        }
    },
    setFiltersForMenus(state, payload: SetFiltersForMenus) {
        state.menusFilters.cocinaId = payload.cocinaId;
        state.menusFilters.centroId = payload.centroId;
        state.menusFilters.dietaId = payload.dietaId;
        state.menusFilters.month = payload.month || null;
        state.menusFilters.week = payload.week || null;
        state.menusFilters.year = payload.year || null;
        state.menusFilters.date = payload.date || null;
    },
    setFiltersForPactos(state, payload: SetFiltersForPactos) {
        state.pactosFilters.centroId = payload.centroId;
    },
    setFiltersForComplejos(state, payload: SetFiltersForComplejos) {
        state.complejosFilters.soloActivos = payload.soloActivos;
    },
    setFiltersForCocinas(state, payload: SetFiltersForCocinas) {
        state.cocinasFilters.soloActivos = payload.soloActivos;
    },
    setFiltersForDietas(state, payload: SetFiltersForDietas) {
        state.dietasFilters.filter = payload.filter;
        state.dietasFilters.soloActivos = payload.soloActivos;
    },
    setFiltersForPreparaciones(state, payload: SetFiltersForPreparaciones) {
        state.preparacionesFilters.filter = payload.filter;
        state.preparacionesFilters.soloActivos = payload.soloActivos;
    },
} as MutationTree<HomeState>;
