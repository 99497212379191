import { AbilityBuilder, Ability } from '@casl/ability';
import { Plugin } from 'vuex';
import { CoquusState } from '.';
import LoggedUser from '@/auth/user';
import { permissionsMap } from '@/auth/ability';
import { SetUser } from './mutation-payloads';

export const ability = new Ability();

function updateRules(user: LoggedUser | null): void {
    const { rules, can } = AbilityBuilder.extract();

    function addRule([action, subject]: [string, string]) {
        can(action, subject);
    }

    if (user) {
        for (const permission of user.permissions) {
            // @ts-expect-error No me apetece mirar como arreglar los tipos para que no proteste
            const map = permissionsMap[permission];
            if (map) {
                if (Array.isArray(map[0])) {
                    map.forEach((x: [string, string]) => addRule(x));
                } else {
                    addRule(map);
                }
            }
        }
    }

    ability.update([]);
    ability.update(rules);
}

export const abilityPlugin: Plugin<CoquusState> = (store) => {
    updateRules(store.state.loggedUser);

    return store.subscribe((mutation) => {
        if (mutation.payload instanceof SetUser) {
            updateRules(mutation.payload.user);
        }
    });
};
