import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Centros_Acceso, Centros_Admin } from '@/auth/permissions';

const CentrosList = () => import(/* webpackChunkName: "centros" */ './list/index.vue');
const CentroDetail = () => import(/* webpackChunkName: "centros" */ './detail/index.vue');
const ServicioItem = () => import(/* webpackChunkName: "servicio" */ './detail/servicios/servicio-item/index.vue');
const DeliveryRouteDetail = () => import(/* webpackChunkName: "delivery" */ './detail/meal-delivery/delivery-route-detail/index.vue');

export default {
    path: 'centros',
    name: 'home.centros',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.centros.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "centros" */ './store');
        next();
    },
    meta: {
        permissions: [Centros_Acceso, Centros_Admin],
        anyPermissionValid: true,
        title: 'centros',
    },
    children: [
        {
            path: '',
            name: 'home.centros.list',
            component: CentrosList,
        },
        {
            path: ':centroId',
            name: 'home.centros.detail',
            component: { render: () => h('router-view') },
            redirect: { name: 'home.centros.item' },
            children: [
                {
                    path: '',
                    name: 'home.centros.item',
                    component: CentroDetail,
                    props: true,
                },
                {
                    path: 'servicios/:servicioId',
                    name: 'home.centros.detail.servicio',
                    component: ServicioItem,
                    props: true,
                },
                {
                    path: 'meal-delivery-routes/:routeId',
                    name: 'home.centros.detail.delivery-route',
                    component: DeliveryRouteDetail,
                    props: (r) => ({ centroId: r.params.centroId, routeId: Number.parseInt(r.params.routeId, 10) }),
                },
            ],
        },
    ],
} as RouteConfig;
