import { PluginObject } from 'vue';
import moment, { Moment } from 'moment';
import i18n from './i18n';
import memoizeFormatConstructor from 'intl-format-cache';

// Cachear instancia de NumberFormat según opciones
const getNumberFormat = memoizeFormatConstructor(Intl.NumberFormat);

export function formatDate(date: Moment | Date | null | undefined, format = 'L LT'): string {
    if (!date) {
        return '';
    }

    const m = moment(date).locale(moment.locale());
    //Se lee el locale de i18n para que vuelva a ejecutar el computed al cambiar de locale
    return i18n.locale && m.isValid() ? m.format(format) : '';
}

function formatNumber(n: number | null | undefined, fractionDigits: number | null | undefined, min = false): string {
    if (n === null || n === undefined) {
        return '';
    }

    const options: Intl.NumberFormatOptions = {};
    if (fractionDigits !== null && fractionDigits !== undefined) {
        if (min) {
            options.minimumFractionDigits = fractionDigits;
        } else {
            options.maximumFractionDigits = fractionDigits;
        }
    }

    return getNumberFormat(i18n.locale, options).format(n);
}

function formatPercent(n: number, fractionDigits = 2): string {
    if (n === null || n === undefined) {
        return '';
    }

    const options: Intl.NumberFormatOptions = {
        style: 'percent',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    };

    return getNumberFormat(i18n.locale, options).format(n);
}

export default {
    install(Vue: any) {
        Object.defineProperty(Vue, 'formatDate', { value: formatDate, writable: false, configurable: false, enumerable: false });
        Object.defineProperty(Vue, 'formatNumber', { value: formatNumber, writable: false, configurable: false, enumerable: false });
        Object.defineProperty(Vue, 'formatPercent', { value: formatPercent, writable: false, configurable: false, enumerable: false });
        // Hacer que esté disponible en los componentes
        Object.defineProperty(Vue.prototype, '$formatDate', { value: formatDate, writable: false, configurable: false, enumerable: false });
        Object.defineProperty(Vue.prototype, '$formatNumber', { value: formatNumber, writable: false, configurable: false, enumerable: false });
        Object.defineProperty(Vue.prototype, '$formatPercent', { value: formatPercent, writable: false, configurable: false, enumerable: false });
    },
} as PluginObject<never>;
