import { Payload } from 'vuex';
import LoggedUser from '../auth/user';
import { Exclusion } from '../exclusiones/models/exclusion';
import { Preparacion } from '../preparaciones/models/preparacion';
import { Culture } from '../langs/models/culture';
import { MotivoCambioDieta } from '../dietas/models/motivo-cambio-dieta';
import { UnidadMedida } from '@/unidades-medida/models/unidad-medida';
import { UnidadMatriz, Fuente } from '@/articulos/models/alimento';
import { Dieta } from '@/dietas/models/dieta';
import { Ingesta } from '@/ingestas/models/ingesta';
import { Temporada } from '@/temporadas/models/temporada';
import { TipoPlato } from '@/tipo-platos/models/tipo-plato';
import { Complejo } from '@/complejos/models/complejo';
import { Facility } from '@/centros/models/centro';
import { PointOfCare } from '@/servicios/models/servicio';

export class SetUser implements Payload {
    public type = 'setUser';

    constructor(public user: LoggedUser | null) {}
}

export class SetExclusiones implements Payload {
    public type = 'setExclusiones';

    constructor(public exclusiones: Exclusion[]) {}
}

export class SetPreparaciones implements Payload {
    public type = 'setPreparaciones';

    constructor(public preparaciones: Preparacion[]) {}
}

export class SetCultures implements Payload {
    public type = 'setCultures';

    constructor(public cultures: Culture[]) {}
}

export class SetMotivosCambioDieta implements Payload {
    public type = 'setMotivosCambioDieta';

    constructor(public motivosCambioDieta: MotivoCambioDieta[]) {}
}

export class SetUnidadesMedida implements Payload {
    public type = 'setUnidadesMedida';

    constructor(public unidadesMedida: UnidadMedida[]) {}
}

export class SetUnidadesMedidaLoading implements Payload {
    public type = 'setUnidadesMedidaLoading';

    constructor(public value: boolean) {}
}

export class SetUnidadesMatriz implements Payload {
    public type = 'setUnidadesMatriz';

    constructor(public unidadesMatriz: UnidadMatriz[]) {}
}

export class SetFuentes implements Payload {
    public type = 'setFuentes';

    constructor(public fuentes: Fuente[]) {}
}

export class SetDietas implements Payload {
    public type = 'setDietas';

    constructor(public dietas: Dieta[]) {}
}

export class SetIngestas implements Payload {
    public type = 'setIngestas';

    constructor(public ingestas: Ingesta[]) {}
}

export class SetTemporadas implements Payload {
    public type = 'setTemporadas';

    constructor(public temporadas: Temporada[]) {}
}

export class SetTipoPlatos implements Payload {
    public type = 'setTipoPlatos';

    constructor(public tipoPlatos: TipoPlato[]) {}
}

export class SetComplejos implements Payload {
    public type = 'setComplejos';

    constructor(public complejos: Complejo[]) {}
}

export class AddCentroToLoggedUser implements Payload {
    public type = 'addCentroToLoggedUser';

    constructor(public centro: Facility) {}
}

export class AddServicioToLoggedUser implements Payload {
    public type = 'addServicioToLoggedUser';

    constructor(public servicio: PointOfCare) {}
}

export class SetUserLocale implements Payload {
    public type = 'setUserLocale';

    constructor(public userLocale: string) {}
}

export class UpdateLoggedUserCentro implements Payload {
    public type = 'updateLoggedUserCentro';
    constructor(public centroId: string) {}
}

export class UpdateLoggedUserServicio implements Payload {
    public type = 'updateLoggedUserServicio';
    constructor(public servicioId: string) {}
}

export class SetNutrientGrids implements Payload {
    public type = 'setNutrientGrids';

    constructor(public nutrientGrids: { id: number; name: string }[]) {}
}

export class SetNutrientGridsLoading implements Payload {
    public type = 'setNutrientGridsLoading';

    constructor(public value: boolean) {}
}
