export class Limit {
    public cocinas: CocinaLimit[];

    public constructor(data: { cocinas?: CocinaLimit[] } = {}) {
        this.cocinas = data.cocinas || [];
    }

    public *flatten(): IterableIterator<{ cocinaId: number; centroId: string | null; servicioId: string | null }> {
        for (const cocina of this.cocinas) {
            if (cocina.centros.length) {
                for (const centro of cocina.centros) {
                    if (centro.servicios.length) {
                        for (const servicio of centro.servicios) {
                            yield { cocinaId: cocina.id, centroId: centro.id, servicioId: servicio.id };
                        }
                    } else {
                        yield { cocinaId: cocina.id, centroId: centro.id, servicioId: null };
                    }
                }
            } else {
                yield { cocinaId: cocina.id, centroId: null, servicioId: null };
            }
        }
    }
}

export class CocinaLimit {
    public id: number;
    public nombre: string;
    public centros: CentroLimit[];

    public constructor(data: { id: number; nombre: string; centros?: CentroLimit[] }) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.centros = data.centros || [];
    }
}

export class CentroLimit {
    public id: string;
    public nombre: string;
    public servicios: ServicioLimit[];

    public constructor(data: { id: string; nombre: string; servicios?: ServicioLimit[] }) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.servicios = data.servicios || [];
    }
}

export class ServicioLimit {
    public id: string;
    public nombre: string;

    public constructor(data: { id: string; nombre: string }) {
        this.id = data.id;
        this.nombre = data.nombre;
    }
}
