export interface ComplejoData {
    id: number;
    nombre: string;
    imagen: boolean;
    isDeleted: boolean;
}

export class Complejo {
    public id: number;
    public nombre: string;
    public imagen: boolean;
    public isDeleted: boolean;

    public constructor({ id, nombre, imagen, isDeleted }: ComplejoData = {} as ComplejoData) {
        this.id = id;
        this.nombre = nombre;
        this.imagen = imagen;
        this.isDeleted = isDeleted;
    }
}
