import { Module } from 'vuex';
import { Moment } from 'moment';

import mutations from './mutations';

import store, { CoquusState } from '../../store';

import { Facility } from '../../centros/models/centro';
import { PointOfCare } from '../../servicios/models/servicio';
import { TipoArticulo } from '@/articulos/models/tipo-articulo';
import { Dieta } from '@/dietas/models/dieta';
import { Plato } from '@/platos/models/plato';
import { Articulo } from '@/articulos/models/articulo';
import { Ingesta } from '@/ingestas/models/ingesta';

export interface HistoryFilters {
    desde: Moment | null;
    hasta: Moment | null;
    identificacion: string | null;
    centros: Facility[];
    dieta: Dieta | null;
    ingesta: Ingesta | null;
    platos: Plato[];
    articulos: Articulo[];
    conAcciones: boolean;
    withLateRequests: boolean;
    withGuests: boolean;
}

export interface PlatosFilters {
    cocinaId: number | null;
    selectedDietaId: number | null;
    soloActivos: boolean;
    filtro: string;
}

export interface MenusFilters {
    cocinaId: number | null;
    centroId: string | null;
    dietaId: number | null;
    showAllOptions: boolean;
    year: number | null;
    month: number | null;
    week: number | null;
    date: Moment | null;
}

export interface PactosFilters {
    centroId: string | null;
}

export interface ComplejosFilters {
    soloActivos: boolean;
}
export interface CocinasFilters {
    soloActivos: boolean;
}

export interface DietasFilters {
    filter: string;
    soloActivos: boolean;
}

export interface PreparacionesFilters {
    filter: string;
    soloActivos: boolean;
}

export interface ArticulosFilters {
    cocinaId: number | null;
    tipo: TipoArticulo;
    soloActivos: boolean;
    filtro: string;
}

export interface HomeState {
    comensalesFilters: {
        centroId: string | null;
        servicioId: string | null;
    };
    reposicionesFilters: {
        selectedCentro: Facility | null;
        selectedServicio: PointOfCare | null;
        selectedDate: Moment | null;
        pendientes: boolean;
        conSolicitud: boolean;
    };
    articulosFilters: ArticulosFilters;
    platosFilters: PlatosFilters;
    historyFilters: HistoryFilters;
    menusFilters: MenusFilters;
    pactosFilters: PactosFilters;
    complejosFilters: ComplejosFilters;
    cocinasFilters: CocinasFilters;
    dietasFilters: DietasFilters;
    preparacionesFilters: PreparacionesFilters;
}

store.registerModule('home', {
    namespaced: true,
    state: {
        comensalesFilters: {
            centroId: null,
            servicioId: null,
        },
        reposicionesFilters: {
            selectedCentro: null,
            selectedServicio: null,
            selectedDate: null,
            pendientes: false,
            conSolicitud: false,
        },
        articulosFilters: {
            cocinaId: null,
            tipo: TipoArticulo.NotSet,
            soloActivos: true,
            filtro: '',
        },
        platosFilters: {
            cocinaId: null,
            selectedDietaId: null,
            soloActivos: true,
            filtro: '',
        },
        historyFilters: {
            desde: null,
            hasta: null,
            identificacion: null,
            centros: [],
            dieta: null,
            ingesta: null,
            platos: [],
            articulos: [],
            conAcciones: true,
            withLateRequests: false,
            withGuests: false,
        },
        menusFilters: {
            cocinaId: null,
            centroId: null,
            dietaId: null,
            showAllOptions: false,
            year: null,
            month: null,
            week: null,
            date: null,
        },
        pactosFilters: {
            centroId: null,
        },
        complejosFilters: {
            soloActivos: true,
        },
        cocinasFilters: {
            soloActivos: true,
        },
        dietasFilters: {
            filter: '',
            soloActivos: true,
        },
        preparacionesFilters: {
            filter: '',
            soloActivos: true,
        },
    },
    mutations,
} as Module<HomeState, CoquusState>);
