import axios from 'axios';

import { UnidadMedida } from '../models/unidad-medida';
import { Conversion } from '@/articulos/models/conversion';

export enum UnidadesMedidaInclude {
    None = 0,
    Conversiones = 1,
}

export async function getUnidadesMedida({ soloActivos = true, filtro }: { soloActivos?: boolean; filtro?: string } = {}) {
    const response = await axios.get('unidades-medida', { params: { soloActivos, filtro } });

    return [...response.data.unidadesMedida].map((um) => new UnidadMedida(um));
}

export async function getUnidadMedida(unidadMedidaId: number) {
    const response = await axios.get(`unidades-medida/${unidadMedidaId}`);

    if (!response.data || !response.data.unidadMedida) {
        throw new Error('Expected data');
    }

    return new UnidadMedida({
        ...response.data.unidadMedida,
        conversiones: [...response.data.unidadMedida.conversiones].map(
            (c) =>
                new Conversion({
                    ...c,
                    unidadMedida: new UnidadMedida(c.unidadMedida),
                }),
        ),
    });
}

export async function updateUnidadMedida(
    unidadMedidaId: number,
    {
        nombre,
        codigo,
        isDeleted,
        conversiones,
    }: {
        nombre?: string;
        codigo?: string | null;
        isDeleted?: boolean;
        conversiones?: Array<{ unidadMedidaId: number; factorConversion: number }>;
    },
) {
    await axios.patch(`unidades-medida/${unidadMedidaId}`, {
        nombre,
        codigo,
        isDeleted,
        conversiones,
    });
}

export async function createUnidadMedida({
    nombre,
    codigo,
    conversiones,
}: {
    nombre: string;
    codigo: string | null;
    conversiones?: Array<{ unidadMedidaId: number; factorConversion: number }>;
}) {
    const response = await axios.post(`unidades-medida`, {
        nombre,
        codigo,
        conversiones,
    });
    return new UnidadMedida(response.data.unidadMedida);
}
