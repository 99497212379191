import { useI18n } from '@/utilities/composition-api/i18n';
import { defineComponent, h, computed } from 'vue';
import moment, { Moment } from 'moment';
import { object, oneOfType, string } from 'vue-types';

export default defineComponent({
    name: 'DinerAge',
    props: {
        diner: object<{ birthDate?: string | Moment }>().isRequired,
        now: oneOfType([object<Moment>(), object<Date>(), string()]).def(() => moment()),
    },
    setup(props) {
        const { i18n } = useI18n();

        const birthDate = computed(() => moment(props.diner.birthDate));
        const now = computed(() => moment(props.now));

        const years = computed(() => now.value.diff(birthDate.value, 'years', false));
        const months = computed(() => now.value.diff(birthDate.value, 'months', false));
        const days = computed(() => now.value.diff(birthDate.value, 'days', false));

        const age = computed(() => years.value || months.value || days.value);
        const unit = computed(() => {
            if (years.value) return 'years';
            if (months.value) return 'months';
            return 'days';
        });

        return () =>
            props.diner.birthDate ? h('span', { attrs: { title: i18n.t('age') } }, [i18n.t('numAge', { age: age.value, unit: unit.value }) as string]) : h();
    },
});
