import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Complejos_Acceso, Complejos_Admin } from '@/auth/permissions';

const ComplejosList = () => import(/* webpackChunkName: "complejos" */ './list/index.vue');
const ComplejoDetail = () => import(/* webpackChunkName: "complejos" */ './detail/index.vue');

export default {
    path: 'complejos',
    name: 'home.complejos',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.complejos.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "complejos" */ './store');
        next();
    },
    meta: {
        permissions: [Complejos_Acceso, Complejos_Admin],
        anyPermissionValid: true,
        title: 'complejos',
    },
    children: [
        {
            path: '',
            name: 'home.complejos.list',
            component: ComplejosList,
        },
        {
            path: ':complejoId',
            name: 'home.complejos.detail',
            component: ComplejoDetail,
        },
    ],
} as RouteConfig;
