import Vue from 'vue';

import ConfirmModal from './confirm-modal.vue';

interface ConfirmModalPropsData {
    size?: 'default' | 'tiny' | 'small' | 'large';
    color?: 'primary' | 'secondary' | 'success' | 'warning' | 'alert' | 'dark' | 'info';
    title: string;
    message: string;
    okText?: string;
    cancelText?: string;
    hideCancel?: boolean;
    hideOk?: boolean;
}

function openConfirmModal(parent: Vue, propsData: ConfirmModalPropsData): Promise<boolean> {
    const component = new ConfirmModal({
        el: document.createElement('div'),
        propsData: propsData || {},
        parent,
    });

    parent.$once('hook:destroyed', () => component.$destroy());

    return new Promise<boolean>((resolve) => {
        component.$on('close', (result: boolean) => {
            component.$destroy();
            resolve(result);
        });
    });
}

export { ConfirmModal, openConfirmModal };
