import Vue from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import moment from 'moment';
import { locale as chageDevExtemeLocale, loadMessages } from 'devextreme/localization';

import { getDefaultUserLocale } from './locale';
import { CustomFormatter } from './formatter';
import { DayOfWeek } from '@/utils';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    preserveDirectiveContent: true,
    formatter: new CustomFormatter(),
    messages: {
        en: require('../locales/en.yaml'),
    },
});

let weekdaysCache: Array<{ name: string; dayOfWeek: DayOfWeek; isoWeekday: number }> = [];

export async function changeLocale(localeName: string): Promise<void> {
    const i18nLocale = localeName.split('-')[0];
    if (i18n.messages[i18nLocale]) {
        weekdaysCache = [];
        moment.locale(localeName);
        await loadDevExtremeMessages(i18nLocale);
        chageDevExtemeLocale(localeName);
        i18n.locale = i18nLocale;
        i18n.formatter = new CustomFormatter(localeName);
        return;
    }

    const messages = await fetchLocale(i18nLocale);

    if (messages) {
        weekdaysCache = [];
        moment.locale(localeName);
        await loadDevExtremeMessages(i18nLocale);
        chageDevExtemeLocale(localeName);
        i18n.setLocaleMessage(i18nLocale, messages);
        i18n.locale = i18nLocale;
        i18n.formatter = new CustomFormatter(localeName);
    }
}

async function fetchLocale(localeName: string): Promise<LocaleMessageObject | null> {
    try {
        const locale: LocaleMessageObject = await import(
            /* webpackChunkName: "i18n-locale-[request]" */ /* webpackExclude: /en\.yaml$/ */ `../locales/${localeName}.yaml`
        );

        if (typeof locale.default === 'object') {
            return locale.default as LocaleMessageObject;
        }

        return locale;
    } catch {
        return null;
    }
}

async function loadDevExtremeMessages(localeName: string): Promise<void> {
    let messages = null;

    try {
        messages = await import(/* webpackChunkName: "devextreme-messages-[request]" */ `./devextreme/${localeName}.json`);
    } catch {
        // empty
    }

    if (messages) {
        loadMessages(messages);
        return;
    }

    try {
        messages = await import(/* webpackChunkName: "devextreme-messages-[request]" */ `devextreme/localization/messages/${localeName}.json`);
    } catch {
        // empty
    }

    if (messages) {
        loadMessages(messages);
        return;
    }
}

export function initLocale(): Promise<void> {
    return changeLocale(getDefaultUserLocale());
}

// Se pide el locales de i18n para que vue sepa que tiene que volver a ejecutar el computed al cambiar de locale
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function weekdays(locale: string) {
    if (weekdaysCache.length) {
        return weekdaysCache;
    }

    const firstDayOfWeek = moment.localeData().firstDayOfWeek();
    weekdaysCache = moment.weekdaysShort().map((value, idx) => ({
        name: value,
        dayOfWeek: idx,
        isoWeekday: moment().isoWeekday(value).isoWeekday(),
    }));

    if (firstDayOfWeek > 0 && firstDayOfWeek < weekdaysCache.length) {
        weekdaysCache = ([] as Array<{ name: string; dayOfWeek: DayOfWeek; isoWeekday: number }>).concat(
            weekdaysCache.splice(firstDayOfWeek, weekdaysCache.length),
            weekdaysCache.splice(0, firstDayOfWeek),
        );
    }

    return weekdaysCache;
}

export default i18n;
