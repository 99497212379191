import './http';
import userManager, { signin } from './user-manager';

import store from '../store';
import { SetUser } from '../store/mutation-payloads';

userManager.events.addUserUnloaded(() => {
    store.commit(new SetUser(null));
});

userManager.events.addUserSignedOut(async () => {
    await userManager.signoutCallback();
    await signin();
});
