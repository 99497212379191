import { Exclusion } from '@/exclusiones/models/exclusion';

export interface PreparacionData {
    id: number;
    nombre: string;
    codigo: string;
    description?: string | null;
    informar?: boolean;
    isDeleted: boolean;
    exclusiones?: Exclusion[];
}

export class Preparacion {
    public id: number;
    public nombre: string;
    public codigo: string;
    public description?: string | null;
    public informar: boolean;
    public isDeleted: boolean;
    public exclusiones: Exclusion[];

    public constructor(data: PreparacionData = {} as PreparacionData) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.codigo = data.codigo;
        this.description = data.description ?? '';
        this.informar = data.informar ?? false;
        this.isDeleted = data.isDeleted;
        this.exclusiones = data.exclusiones || [];
    }
}
