import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import LoggedUser from '../auth/user';
import { Exclusion } from '@/exclusiones/models/exclusion';
import { Preparacion } from '@/preparaciones/models/preparacion';
import { Culture } from '@/langs/models/culture';
import { MotivoCambioDieta } from '@/dietas/models/motivo-cambio-dieta';
import { UnidadMedida } from '@/unidades-medida/models/unidad-medida';
import { UnidadMatriz, Fuente } from '@/articulos/models/alimento';
import { Dieta } from '@/dietas/models/dieta';
import { Ingesta } from '@/ingestas/models/ingesta';
import { Temporada } from '@/temporadas/models/temporada';
import { TipoPlato } from '@/tipo-platos/models/tipo-plato';
import { Complejo } from '@/complejos/models/complejo';
import { abilityPlugin } from './ability';
import { HomeState } from '@/home/store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export interface CoquusState {
    loggedUser: LoggedUser | null;
    cache: {
        exclusiones: Exclusion[];
        preparaciones: Preparacion[];
        cultures: Culture[];
        motivosCambioDieta: MotivoCambioDieta[];
        unidadesMedida: UnidadMedida[];
        unidadesMedidaLoading: boolean;
        unidadesMatriz: UnidadMatriz[];
        fuentes: Fuente[];
        dietas: Dieta[];
        ingestas: Ingesta[];
        temporadas: Temporada[];
        tipoPlatos: TipoPlato[];
        complejos: Complejo[];
        nutrientGridsLoading: boolean;
        nutrientGrids: { id: number; name: string }[];
    };
    home?: HomeState;
}

const store = new Store<CoquusState>({
    plugins: [abilityPlugin],
    state: {
        loggedUser: null,
        cache: {
            exclusiones: [],
            preparaciones: [],
            cultures: [],
            motivosCambioDieta: [],
            unidadesMedida: [],
            unidadesMedidaLoading: false,
            unidadesMatriz: [],
            fuentes: [],
            dietas: [],
            ingestas: [],
            temporadas: [],
            tipoPlatos: [],
            complejos: [],
            nutrientGridsLoading: false,
            nutrientGrids: [],
        },
    },
    mutations,
    actions,
    getters,
    modules: {
        security: {
            namespaced: true,
        },
    },
    strict: debug,
});

export default store;
