import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Roles_Acceso, Roles_Admin } from '../../auth/permissions';

const RoleList = (r: any) => import(/* webpackChunkName: "security-roles" */ './list/index.vue').then(r);
const RoleDetail = (r: any) => import(/* webpackChunkName: "security-roles" */ './detail/index.vue').then(r);
const RoleCreate = (r: any) => import(/* webpackChunkName: "security-roles" */ './create/index.vue').then(r);

export default {
    path: 'security/roles',
    name: 'home.security.roles',
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "security-roles" */ './store');
        next();
    },
    component: { render: () => h('router-view') },
    redirect: { name: 'home.security.roles.list' },
    meta: {
        permissions: [Roles_Acceso, Roles_Admin],
        anyPermissionValid: true,
        title: 'roles',
    },
    children: [
        {
            path: '',
            name: 'home.security.roles.list',
            component: RoleList,
            props: (route) => ({
                ...route.params,
            }),
        },
        {
            path: 'create',
            name: 'home.security.roles.create',
            component: RoleCreate,
            meta: {
                permissions: [Roles_Admin],
            },
        },
        {
            path: ':roleId',
            name: 'home.security.roles.detail',
            component: RoleDetail,
            props: (route) => ({ roleId: parseInt(route.params.roleId, 10) }),
        },
    ],
} as RouteConfig;
