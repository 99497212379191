import { RouteConfig } from 'vue-router';

import { Comensales_Acceso, Comensales_Admin } from '../auth/permissions';

const ComensalesList = () => import(/* webpackChunkName: "comensales" */ './list/index.vue');
const ComensalesExterno = () => import(/* webpackChunkName: "comensales-externo" */ './externo/index.vue');

export default [
    {
        path: 'externo/:identificacion?',
        name: 'home.externoComensales',
        component: ComensalesExterno,
        async beforeEnter(to, from, next) {
            await import(/* webpackChunkName: "comensales" */ './store');
            next();
        },
        meta: {
            tokenLogin: true,
        },
    },
    {
        path: 'centros/:centroId/servicios/:servicioId/comensales',
        name: 'home.comensales',
        component: ComensalesList,
        props: true,
        meta: {
            permissions: [Comensales_Acceso, Comensales_Admin],
            anyPermissionValid: true,
            title: 'comensales',
        },
        async beforeEnter(to, from, next) {
            await import(/* webpackChunkName: "comensales" */ './store');
            next();
        },
    },
] as RouteConfig[];
