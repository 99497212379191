import { Component, Vue, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class MenuDropdown extends Vue {
    @Prop({ type: [Array, String], default: () => null })
    public menuIcon: string[] | string | null;
    @Prop({ type: String, required: true })
    public menuTitle: string;

    public open = false;
    public active = false;

    // eslint-disable-next-line @typescript-eslint/ban-types
    private removeHook: Function | null = null;

    public toggleDropdown() {
        // No cerrar el dropdown si está activo
        if (this.active) {
            return;
        }

        this.open = !this.open;
    }

    private afterEachRoute(to: Route) {
        // El primer children es el collapse-transition
        const links = this.$children[0].$children.filter((c: any) => !!c.to).map((c: any) => c.to);

        if (links.some((l) => to.matched.some((m) => m.name === l.name))) {
            this.active = true;
            this.open = true;
        } else {
            this.active = false;
            this.open = false;
        }
    }

    private created() {
        this.removeHook = this.$router.afterEach(this.afterEachRoute);
    }

    private mounted() {
        this.afterEachRoute(this.$route);
    }

    private destroyed(): void {
        if (this.removeHook) {
            this.removeHook();
        }
    }
}
