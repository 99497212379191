export interface ExclusionData {
    id: number;
    codigo?: string;
    nombre: string;
    intolerancia?: string;
    description?: string | null;
    color?: string | null;
    informar?: boolean;
    isDeleted: boolean;
}

export class Exclusion {
    public id: number;
    public codigo?: string;
    public nombre: string;
    public intolerancia?: string;
    public description?: string | null;
    public color?: string | null;
    public informar: boolean;
    public isDeleted: boolean;

    constructor(data: ExclusionData = {} as ExclusionData) {
        this.id = data.id;
        this.codigo = data.codigo;
        this.nombre = data.nombre;
        this.intolerancia = data.intolerancia;
        this.description = data.description ?? '';
        this.color = data.color;
        this.informar = data.informar !== undefined ? data.informar : false;
        this.isDeleted = data.isDeleted;
    }
}

export enum UnidadProduccionExclusionInputSource {
    Unknown = 0,
    Coquus = 1,
    Externo = 2,
    Proovedor = 3,
}

export enum UnidadProduccionExclusionType {
    Unknown = 0,
    UnidadProduccion = 1,
    Receta = 2,
}
