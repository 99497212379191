import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Nutrientes_Acceso, Nutrientes_Admin } from '@/auth/permissions';

const NutrienteList = () => import(/* webpackChunkName: "nutrientes" */ './list/index.vue');

export default {
    path: 'nutrientes',
    name: 'home.nutrientes',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.nutrientes.list' },
    meta: {
        permissions: [Nutrientes_Acceso, Nutrientes_Admin],
        anyPermissionValid: true,
        title: 'nutrientes',
    },
    children: [
        {
            path: '',
            name: 'home.nutrientes.list',
            component: NutrienteList,
        },
    ],
} as RouteConfig;
