import { Ingesta } from '../../ingestas/models/ingesta';
import { Exclusion } from '../../exclusiones/models/exclusion';
import { Preparacion } from '../../preparaciones/models/preparacion';
import { ModoServicioComida } from './modo-servicio-comida';
import { uniqueId } from '@/utilities/unique-id';

export interface DietaData {
    id: number;
    nombre: string;
    nombreCorto?: string;
    codigo?: string;
    observaciones?: string;
    description?: string;
    maxDias?: number;
    modoServicio?: ModoServicioComida;
    limitarSeleccion?: boolean;
    admiteSeleccion?: boolean;
    ingestas?: Ingesta[];
    exclusiones?: Exclusion[];
    preparaciones?: Preparacion[];
    equivalences?: DietEquivalence[];
    isDeleted?: boolean;
}

export class SourceAttribute {
    public id: number;
    public code: string;
    public description: string;

    public constructor({ id, code, description }: { id: number; code: string; description: string }) {
        this.id = id;
        this.code = code;
        this.description = description;
    }
}

export class DietEquivalence {
    public id: number;
    public attributes: SourceAttribute[];

    public constructor(data?: { id: number; attributes?: SourceAttribute[] }) {
        this.id = data?.id ?? uniqueId();
        this.attributes = data?.attributes ?? [];
    }
}

export class Dieta {
    public id: number;
    public nombre: string;
    public nombreCorto: string;
    public codigo: string;
    public observaciones: string;
    public description?: string | null;
    public maxDias?: number | null;
    public modoServicio: ModoServicioComida;
    public limitarSeleccion: boolean;
    public admiteSeleccion: boolean;
    public ingestas: Ingesta[];
    public exclusiones: Exclusion[];
    public preparaciones: Preparacion[];
    public equivalences: DietEquivalence[];
    public isDeleted: boolean;

    constructor(data: DietaData = {} as DietaData) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.nombreCorto = data.nombreCorto ?? '';
        this.codigo = data.codigo ?? '';
        this.observaciones = data.observaciones ?? '';
        this.description = data.description ?? '';
        this.maxDias = data.maxDias;
        this.modoServicio = data.modoServicio || ModoServicioComida.NotSet;
        this.limitarSeleccion = data.limitarSeleccion ?? false;
        this.admiteSeleccion = data.admiteSeleccion ?? false;
        this.ingestas = data.ingestas ?? [];
        this.exclusiones = data.exclusiones ?? [];
        this.preparaciones = data.preparaciones ?? [];
        this.equivalences = data.equivalences ?? [];
        this.isDeleted = data.isDeleted ?? false;
    }
}
