import 'intersection-observer';

import Vue, { CreateElement } from 'vue';
import vOutsideEvents from 'vue-outside-events';
import Meta from 'vue-meta';
import configDevExtreme from 'devextreme/core/config';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import Transitions from 'vue2-transitions';
import Fuse, { FuseOptions } from 'fuse.js';
import PortalVue from 'portal-vue';
import { abilitiesPlugin } from '@casl/vue';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueObserveVisibility from 'vue-observe-visibility';
import { VueQueryPlugin } from 'vue-query';
import { UnidadMedidaAbbr } from './components/unidad-medida-abbr';
import DinerAge from './components/diner-age';
import TextEllipsis from './components/text-ellipsis.vue';
import { CoquusBreadcrum } from './components/coquus-breadcrum';

import router from './router';
import store from './store';
import i18n, { initLocale } from './i18n';
import { initIntl } from './intl.js';
import formatters from './formatters';
import { FocusTrapZone } from './components/focus-trap-zone';
import { ability } from './store/ability';

import { createPinia, PiniaVuePlugin } from 'pinia';

import './http';
import './auth';
import './class-component-hooks';
import './components/focus';
import './features';

import './scss/index.scss';
import 'vue-popperjs/dist/vue-popper.css';
import 'devextreme/dist/css/dx.common.css';
import './scss/dx.generic.coquus.css';
import './scss/devexpress.scss';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import './fontawesome';

configDevExtreme({
    defaultCurrency: 'EUR',
});

Vue.use(vOutsideEvents);
Vue.use(formatters);
Vue.use(Meta);
Vue.use(Transitions);
Vue.use(PortalVue);
Vue.use(abilitiesPlugin, ability);
Vue.use(VueVirtualScroller);
Vue.use(VueObserveVisibility);
Vue.use(PiniaVuePlugin);
Vue.use(VueQueryPlugin);

Vue.use((v) => {
    v.prototype.$search = (term: string, list: any[], options: FuseOptions<any>) => {
        return new Promise((resolve) => {
            const run = new Fuse(list, options);
            resolve(run.search(term));
        });
    };
});

Vue.component('icon', FontAwesomeIcon as any);
Vue.component('icon-layer', FontAwesomeLayers as any);
Vue.component('icon-layer-text', FontAwesomeLayersText as any);
Vue.component('focus-trap-zone', FocusTrapZone);
Vue.component('unidad-medida-abbr', UnidadMedidaAbbr);
Vue.component('diner-age', DinerAge);
Vue.component('TextEllipsis', TextEllipsis);
Vue.component('CoquusBreadcrum', CoquusBreadcrum);

if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(Vue.prototype, '$log', { value: console.log.bind(console), writable: false, configurable: false, enumerable: false });

    Vue.mixin({
        renderError(h: CreateElement, err) {
            const name = (this as any).$options.name;

            return h('div', { staticClass: 'grid-content' }, [
                h('h1', ['Render Error']),
                h('p', [`Error ocurred during rendering of component "${name}"`]),
                h(
                    'pre',
                    {
                        staticStyle: { color: 'red' },
                    },
                    [err.stack as string],
                ),
            ]);
        },
    });
} else {
    Object.defineProperty(Vue.prototype, '$log', {
        value() {
            /* noop */
        },
        writable: false,
        configurable: false,
        enumerable: false,
    });
}

Vue.config.productionTip = false;

initIntl()
    .then(initLocale)
    .then(() => {
        new Vue({
            router,
            store,
            i18n,
            pinia: createPinia(),
            render(h) {
                return h(
                    'div',
                    {
                        attrs: { id: 'app' },
                        staticClass: 'vertical grid-frame',
                    },
                    [h('router-view'), h('portal-target', { props: { name: 'modal-container', slim: true } })],
                );
            },
        }).$mount('#app');
    });
