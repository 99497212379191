import Oidc from 'oidc-client';
import CookieStore from './cookie-store';

import LoggedUser from './user';
import store from '../store';
import { SetUser } from '../store/mutation-payloads';
import { isCellaAvailable, isCuscusDashboardAvailable } from '@/features';

if (process.env.NODE_ENV === 'development') {
    Oidc.Log.level = Oidc.Log.INFO;
    Oidc.Log.logger = console;
}

const userManagerSettings: Oidc.UserManagerSettings = {
    ...parent.coquus.oidc.settings,
    silentRequestTimeout: 50000,
    response_type: 'code',
    scope:
        'openid profile coquus.full-access custos.token-request coquus.patient-search' +
        (isCellaAvailable() ? ' coquus.cella.read coquus.cella.write' : '') +
        (isCuscusDashboardAvailable() ? ' coquus.cuscus-dashboard.read coquus.cuscus-dashboard.write' : ''),
};

// si el almacenamiento DOM está deshabilitado usar cookiesk
if (!parent.sessionStorage) {
    const stateStore = new Oidc.WebStorageStateStore({
        store: new CookieStore(parent.coquus.appUri),
    });

    // @ts-expect-error no es readonly
    userManagerSettings.userStore = stateStore;
    // @ts-expect-error no es readonly
    userManagerSettings.stateStore = stateStore;
}

const userManager = new Oidc.UserManager(userManagerSettings);

export async function signin(): Promise<void> {
    const user = await userManager.signinSilent().catch((e) => {
        userManager.signinRedirect({
            data: { returnTo: parent.location.href },
        });

        return Promise.reject(e);
    });

    const newUser = new LoggedUser(user);
    await newUser.initAccesos();
    store.commit(new SetUser(newUser));
}

/** Iniciar autenticación con token que validará el IdP */
export async function tokenSignin(token: string, returnTo?: string): Promise<void> {
    // Quitar usuario de la store.
    await userManager.removeUser();
    // Solicitar login.
    await userManager.signinRedirect({
        prompt: 'login',
        data: { returnTo: returnTo || parent.location.href },
        extraQueryParams: {
            userToken: token,
        },
    });
}

/**
 * Forzar autenticación redirigiendo al IdP.
 * Antes de la redirección se llama a {@link userManager.removeUser}.
 * @param {string} [loginHint] - `login_hint` en IdP.
 */
export async function forceSignin(loginHint?: string): Promise<void> {
    // Quitar usuario de la store.
    await userManager.removeUser();
    // Solicitar login.
    await userManager.signinRedirect({
        prompt: 'login',
        login_hint: loginHint,
        data: { returnTo: parent.location.href },
    });
}

export default userManager;
