import axios from 'axios';

import { Ingesta } from '../models/ingesta';

export async function getIngestas({
    centroId,
    soloActivos = true,
    soloConUsos = true,
    dietaId,
    fecha,
}: { centroId?: string; soloActivos?: boolean; soloConUsos?: boolean; dietaId?: number; fecha?: string } = {}) {
    const response = await axios.get('ingestas', {
        params: {
            centroId,
            soloActivos,
            soloConUsos,
            dietaId,
            fecha,
        },
    });

    if (!Array.isArray(response.data.ingestas)) {
        throw new Error('Expected Array');
    }

    return response.data.ingestas.map((i: any) => new Ingesta(i)) as Ingesta[];
}

export async function saveSortedIngestas(data: Array<{ id: number; horaCierre: number }>) {
    await axios.patch('ingestas', { ingestas: data });
}

export async function createIngesta({ nombre, horaCierre }: { nombre: string; horaCierre: number }) {
    const response = await axios.post('ingestas', { nombre, horaCierre });
    return new Ingesta(response.data.ingesta);
}

export async function updateIngesta(ingestaId: number, { nombre, isDeleted }: { nombre?: string; isDeleted?: boolean }) {
    await axios.patch(`ingestas/${ingestaId}`, { nombre, isDeleted });
}
