import Vue from 'vue';
import Router from 'vue-router';
import urljoin from 'url-join';
import qs from 'qs';

import store from './store';
import { SetUser } from './store/mutation-payloads';
import userManager, { signin, tokenSignin } from './auth/user-manager';
import LoggedUser from './auth/user';

import comensalesRoutes from './comensales/routes';
import reposicionesRoutes from './reposiciones/routes';
import cocinaRoutes from './cocina/routes';
import consultasRoutes from './consultas/routes';
import userRoutes from './security/users/routes';
import roleRoutes from './security/roles/routes';
import articulosRoutes from './articulos/routes';
import platosRoutes from './platos/routes';
import menusRoutes from './menus/routes';
import menuPlanRoutes from './menu-plans/routes';
import pactosRoutes from './pactos/routes';
import complejosRoutes from './complejos/routes';
import cocinasRoutes from './cocinas/routes';
import centrosRoutes from './centros/routes';
import dietasRoutes from './dietas/routes';
import ingestasRoutes from './ingestas/routes';
import tipoPlatosRoutes from './tipo-platos/routes';
import exclusionesRoutes from './exclusiones/routes';
import preparacionesRoutes from './preparaciones/routes';
import unidadesMedidaRoutes from './unidades-medida/routes';
import temporadasRoutes from './temporadas/routes';
import nutrientesRoutes from './nutrientes/routes';
import langsRoutes from './langs/routes';
import nutrientGridsRoutes from './nutrition-fact-grids/routes';

import Home from './home/index.vue';

const AuthCallback = () => import(/* webpackChunkName: 'auth-callback' */ '@/auth/auth-callback/index.vue');
const AuthSilentRenew = () => import(/* webpackChunkName: 'auth-silent-renew' */ '@/auth/auth-silent-renew/index.vue');

Vue.use(Router);

const router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
        {
            path: '/auth-callback',
            component: AuthCallback,
        },
        {
            path: '/auth-silent-renew',
            component: AuthSilentRenew,
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            children: [
                ...comensalesRoutes,
                reposicionesRoutes,
                cocinaRoutes,
                userRoutes,
                roleRoutes,
                ...consultasRoutes,
                articulosRoutes,
                platosRoutes,
                menusRoutes,
                menuPlanRoutes,
                pactosRoutes,
                complejosRoutes,
                cocinasRoutes,
                centrosRoutes,
                dietasRoutes,
                ingestasRoutes,
                tipoPlatosRoutes,
                exclusionesRoutes,
                preparacionesRoutes,
                unidadesMedidaRoutes,
                temporadasRoutes,
                nutrientesRoutes,
                langsRoutes,
                nutrientGridsRoutes,
            ],
            async beforeEnter(to, from, next) {
                require('./home/store');
                next();
            },
        },
    ],
});

const excludePaths = ['/auth-callback', '/auth-silent-renew'];

router.beforeEach(async (to, from, next) => {
    if (excludePaths.includes(to.path)) {
        next();
        return;
    }

    // meta tokenLogin permite hacer login con un token
    // si es un string, se usa como nombre del query param, si es true se usa 't'
    if (to.meta?.tokenLogin) {
        const queryParamName = typeof to.meta.tokenLogin === 'string' && to.meta.tokenLogin ? to.meta.tokenLogin : 't';
        const queryParam = to.query[queryParamName];
        const userToken = Array.isArray(queryParam) ? queryParam.find((x) => !!x) : queryParam;

        if (userToken) {
            // No se puede quitar el token de la query antes de hacer el tokenSignin
            // porque hace que vuelva a entrar en este beforeEach
            // e intente hacer el signin normal
            const returnTo = urljoin(document.baseURI, to.path, qs.stringify({ ...to.query, [queryParamName]: undefined }, { addQueryPrefix: true }), to.hash);
            await tokenSignin(userToken, returnTo);
            // No se va al destino porque el tokenSignin va a hacer un redirect a login
            next(false);
            return;
        }
    }

    let user = store.state.loggedUser;

    if (!user || user.expired) {
        const result = await userManager.getUser();
        user = result ? new LoggedUser(result) : null;

        if (!user || user.expired) {
            await signin();
        } else {
            await user.initAccesos();
            store.commit(new SetUser(user));
        }
    }

    next();
});

router.beforeEach((to, from, next) => {
    if (excludePaths.includes(to.path)) {
        next();
    }

    // reverse modifica el array original
    const route = [...to.matched].reverse().find((r) => r.meta.permissions);
    if (route) {
        const permissions: string[] = route.meta.permissions;
        const anyValid = !!route.meta.anyPermissionValid;

        const user = store.state.loggedUser;

        if (user) {
            const func = anyValid ? user.canAccessAny : user.canAccess;

            if (func.bind(user)(...permissions)) {
                next();
            } else {
                next(false);
            }
        } else {
            next(false);
        }
    } else {
        next();
    }
});

export default router;
