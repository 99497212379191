export enum AppPermissions {
    CambiosEmplatado_Acceso = 'CambiosEmplatado_Acceso',
    Centros_Acceso = 'Centros_Acceso',
    Centros_Admin = 'Centros_Admin',
    Cocinas_Acceso = 'Cocinas_Acceso',
    Cocinas_Admin = 'Cocinas_Admin',
    Comensales_Acceso = 'Comensales_Acceso',
    Comensales_Admin = 'Comensales_Admin',
    Comensales_Invitados_Admin = 'Comensales_Invitados_Admin',
    Comensales_History_Acceso = 'Comensales_History_Acceso',
    Comensales_Complementos_Open = 'Comensales_Complementos_Open',
    Comensales_AddOpcionesComida = 'Comensales_AddOpcionesComida',
    ComidasAdjuntos_Acceso = 'ComidasAdjuntos_Acceso',
    Comidas_Acceso = 'ComidasCocina_Acceso',
    Comidas_Admin = 'ComidasCocina_Admin',
    Historico_Acceso = 'ComidasHistorico_Acceso',
    Historico_Admin = 'ComidasHistorico_Admin',
    Complejos_Acceso = 'Complejos_Acceso',
    Complejos_Admin = 'Complejos_Admin',
    Dietas_Acceso = 'Dietas_Acceso',
    Dietas_Admin = 'Dietas_Admin',
    DietasCambios_Admin = 'DietasCambios_Admin',
    DietasPuestos_Acceso = 'DietasPuestos_Acceso',
    DietPrescription_Admin = 'DietPrescription_Admin',
    Exclusiones_Acceso = 'Exclusiones_Acceso',
    Exclusiones_Admin = 'Exclusiones_Admin',
    Ingestas_Acceso = 'Ingestas_Acceso',
    Ingestas_Admin = 'Ingestas_Admin',
    Menus_Acceso = 'Menus_Acceso',
    Menus_Admin = 'Menus_Admin',
    MotivosCambioDieta_Acceso = 'MotivosCambioDieta_Acceso',
    MotivosCambioDieta_Admin = 'MotivosCambioDieta_Admin',
    Pactos_Acceso = 'Pactos_Acceso',
    Pactos_Admin = 'Pactos_Admin',
    Platos_Acceso = 'Platos_Acceso',
    Platos_Admin = 'Platos_Admin',
    PreparacionesCulinarias_Acceso = 'PreparacionesCulinarias_Acceso',
    PreparacionesCulinarias_Admin = 'PreparacionesCulinarias_Admin',
    Reposiciones_Acceso = 'Reposiciones_Acceso',
    Reposiciones_Admin = 'Reposiciones_Admin',
    Reposiciones_Solicitud = 'Reposiciones_Solicitud',
    ServicioPeriodos_Acceso = 'ServicioPeriodos_Acceso',
    ServicioPeriodos_Admin = 'ServicioPeriodos_Admin',
    TarjetasSeleccion_Acceso = 'TarjetasSeleccion_Acceso',
    TarjetasSeleccion_Admin = 'TarjetasSeleccion_Admin',
    TiposPlatos_Acceso = 'TiposPlatos_Acceso',
    TiposPlatos_Admin = 'TiposPlatos_Admin',
    UnidadesMedida_Acceso = 'UnidadesMedida_Acceso',
    UnidadesMedida_Admin = 'UnidadesMedida_Admin',
    UnidadesProduccion_Acceso = 'UnidadesProduccion_Acceso',
    UnidadesProduccion_Admin = 'UnidadesProduccion_Admin',
    Usuarios_Acceso = 'Usuarios_Acceso',
    Usuarios_Admin = 'Usuarios_Admin',
    Langs_Acceso = 'Langs_Acceso',
    Langs_Admin = 'Langs_Admin',
    Nutrientes_Acceso = 'Nutrientes_Acceso',
    Nutrientes_Admin = 'Nutrientes_Admin',
    Roles_Acceso = 'Roles_Acceso',
    Roles_Admin = 'Roles_Admin',
    Estadisticas_Pensiones_Acceso = 'Estadisticas_Pensiones_Acceso',
    Estadisticas_AsignacionExtras_Acceso = 'Estadisticas_AsignacionExtras_Acceso',
    Estadisticas_Pactos_Acceso = 'Estadisticas_Pactos_Acceso',
    Estadisticas_PreciosPlatos_Acceso = 'Estadisticas_PreciosPlatos_Acceso',
    Estadisticas_Produccion_Acceso = 'Estadisticas_Produccion_Acceso',
    Estadisticas_Pensiones_Profiles_Admin = 'Estadisticas_Pensiones_Profiles_Admin',
    Estadisticas_AsignacionExtra_Profiles_Admin = 'Estadisticas_AsignacionExtra_Profiles_Admin',
    Estadisticas_Pactos_Profiles_Admin = 'Estadisticas_Pactos_Profiles_Admin',
    Estadisticas_PreciosPlatos_Profiles_Admin = 'Estadisticas_PreciosPlatos_Profiles_Admin',
    Estadisticas_Produccion_Profiles_Admin = 'Estadisticas_Produccion_Profiles_Admin',
    KioskStats_Read = 'KioskStats_Read',
    Intervenciones_Admin = 'Intervenciones_Admin',
    Acceso_Global = 'Acceso_Global',
    Logs_Acceso = 'Logs_Acceso',
    Custos_Admin = 'Custos_Admin',
    Sync_Issues_Admin = 'Sync_Issues_Admin',
    Vendors_Admin = 'Vendors_Admin',
    VendorItems_Admin = 'VendorItems_Admin',
    Vendors_Read = 'Vendors_Read',
    ProcurementCatalogs_Read = 'ProcurementCatalogs_Read',
    ProcurementCatalogs_Admin_Local = 'ProcurementCatalogs_Admin_Local',
    ProcurementCatalogs_Admin_Global = 'ProcurementCatalogs_Admin_Global',
    Purchases_Read = 'Purchases_Read',
    PurchaseRequisitions_Admin = 'PurchaseRequisitions_Admin',
    PurchaseOrders_Admin = 'PurchaseOrders_Admin',
    PurchaseReceipts_Admin = 'PurchaseReceipts_Admin',
    PurchaseReceipts_Profiles_Admin = 'PurchaseReceipts_Profiles_Admin',
    ConfirmedPurchaseReceipts_Admin = 'ConfirmedPurchaseReceipts_Admin',
    ReceiptSettings_Admin = 'ReceiptSettings_Admin',
    ReceiptDiscrepancies_Admin = 'ReceiptDiscrepancies_Admin',
    PhysicalCounts_Read = 'PhysicalCounts_Read',
    PhysicalCounts_Admin = 'PhysicalCounts_Admin',
    Taxes_Admin = 'Taxes_Admin',
    Taxes_Read = 'Taxes_Read',
    InventoryAdjustmentReasons_Admin = 'InventoryAdjustmentReasons_Admin',
    InventoryAdjustmentReasons_Read = 'InventoryAdjustmentReasons_Read',
    InventoryAdjustments_Admin = 'InventoryAdjustments_Admin',
    InventoryAdjustments_Read = 'InventoryAdjustments_Read',
    InventoryTransfers_Admin = 'InventoryTransfers_Admin',
    InventoryTransfers_Read = 'InventoryTransfers_Read',
    Inventory_Read = 'Inventory_Read',
    MerchandiseReturnReasons_Read = 'MerchandiseReturnReasons_Read',
    MerchandiseReturnReasons_Admin = 'MerchandiseReturnReasons_Admin',
    ReturnsToVendor_Admin = 'ReturnsToVendor_Admin',
    ReturnsToVendor_Read = 'ReturnsToVendor_Read',
    PurchaseTemplates_Admin = 'PurchaseTemplates_Admin',
    PurchaseTemplates_Read = 'PurchaseTemplates_Read',
    ProductionOrders_Admin = 'ProductionOrders_Admin',
    ProductionOrders_Work = 'ProductionOrders_Work',
    ProductionOrders_Read = 'ProductionOrders_Read',
    ControlParameters_Admin = 'ControlParameters_Admin',
    ControlParameters_Read = 'ControlParameters_Read',
    InspectionPlans_Admin = 'InspectionPlans_Admin',
    InspectionPlans_Read = 'InspectionPlans_Read',
    HaccpIssues_Admin = 'HaccpIssues_Admin',
    HaccpIssues_Read = 'HaccpIssues_Read',
    MealtimePriceList_Admin = 'MealtimePriceList_Admin',
}

export const permissionsMap = {
    [AppPermissions.Comensales_Acceso]: ['read', 'comensales'] as const,
    [AppPermissions.Comensales_Admin]: ['manage', 'comensales'] as const,
    [AppPermissions.Comensales_Invitados_Admin]: [
        ['manage', 'comensalesInvitados'],
        ['read', 'comensales'],
    ] as const,
    [AppPermissions.Comensales_Complementos_Open]: ['avoid', 'complementosLimit'] as const,
    [AppPermissions.Comensales_AddOpcionesComida]: ['add', 'comensalOpcionesComida'] as const,
    [AppPermissions.Sync_Issues_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'syncIssues'],
        ['read', 'comensales'],
    ] as const,
    [AppPermissions.Reposiciones_Solicitud]: [
        ['manage', 'solicitudReposiciones'],
        ['read', 'reposiciones'],
    ] as const,
    [AppPermissions.Reposiciones_Admin]: [
        ['manage', 'solicitudReposiciones'],
        ['manage', 'reposiciones'],
    ] as const,
    [AppPermissions.Reposiciones_Acceso]: ['read', 'reposiciones'] as const,
    [AppPermissions.Comidas_Acceso]: ['read', 'cocina'] as const,
    [AppPermissions.Comidas_Admin]: ['manage', 'cocina'] as const,
    [AppPermissions.Estadisticas_Pensiones_Acceso]: [
        ['read', 'consultas'],
        ['read', 'pensiones'],
    ] as const,
    [AppPermissions.Estadisticas_Produccion_Acceso]: [
        ['read', 'consultas'],
        ['read', 'produccion'],
    ] as const,
    [AppPermissions.Estadisticas_AsignacionExtras_Acceso]: [
        ['read', 'consultas'],
        ['read', 'asignacionExtras'],
    ] as const,
    [AppPermissions.Estadisticas_Pactos_Acceso]: [
        ['read', 'consultas'],
        ['read', 'pactoConsumos'],
    ] as const,
    [AppPermissions.Estadisticas_PreciosPlatos_Acceso]: [
        ['read', 'consultas'],
        ['read', 'preciosPlatos'],
    ] as const,
    [AppPermissions.KioskStats_Read]: [
        ['read', 'consultas'],
        ['read', 'kioskStats'],
    ] as const,
    [AppPermissions.Estadisticas_Pensiones_Profiles_Admin]: ['manage', 'profiles'] as const,
    [AppPermissions.Estadisticas_Produccion_Profiles_Admin]: ['manage', 'profiles'] as const,
    [AppPermissions.Estadisticas_AsignacionExtra_Profiles_Admin]: ['manage', 'profiles'] as const,
    [AppPermissions.Estadisticas_Pactos_Profiles_Admin]: ['manage', 'profiles'] as const,
    [AppPermissions.Estadisticas_PreciosPlatos_Profiles_Admin]: ['manage', 'profiles'] as const,
    [AppPermissions.Comensales_History_Acceso]: [
        ['read', 'consultas'],
        ['read', 'comensalesHistory'],
    ] as const,
    [AppPermissions.UnidadesProduccion_Acceso]: [
        ['read', 'administracion'],
        ['read', 'articulos'],
        ['read', 'materials'],
        ['read', 'materialGroups'],
    ] as const,
    [AppPermissions.UnidadesProduccion_Admin]: [
        ['read', 'administracion'],
        ['manage', 'articulos'],
        ['manage', 'materials'],
        ['manage', 'materialGroups'],
    ] as const,
    [AppPermissions.Platos_Acceso]: [
        ['read', 'administracion'],
        ['read', 'platos'],
    ] as const,
    [AppPermissions.Platos_Admin]: [
        ['read', 'administracion'],
        ['manage', 'platos'],
    ] as const,
    [AppPermissions.Menus_Acceso]: [
        ['read', 'administracion'],
        ['read', 'menus'],
    ] as const,
    [AppPermissions.Menus_Admin]: [
        ['read', 'administracion'],
        ['manage', 'menus'],
    ] as const,
    [AppPermissions.Pactos_Acceso]: [
        ['read', 'administracion'],
        ['read', 'pactos'],
    ] as const,
    [AppPermissions.Pactos_Admin]: [
        ['read', 'administracion'],
        ['manage', 'pactos'],
    ] as const,
    [AppPermissions.Complejos_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'complejos'],
    ] as const,
    [AppPermissions.Complejos_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'complejos'],
    ] as const,
    [AppPermissions.Cocinas_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'cocinas'],
        ['read', 'kitchens'],
        ['read', 'locations'],
    ] as const,
    [AppPermissions.Cocinas_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'cocinas'],
        ['manage', 'kitchens'],
        ['manage', 'locations'],
    ] as const,
    [AppPermissions.Centros_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'centros'],
    ] as const,
    [AppPermissions.Centros_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'centros'],
    ] as const,
    [AppPermissions.Dietas_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'dietas'],
    ] as const,
    [AppPermissions.Dietas_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'dietas'],
    ] as const,
    [AppPermissions.Ingestas_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'ingestas'],
    ] as const,
    [AppPermissions.Ingestas_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'ingestas'],
    ] as const,
    [AppPermissions.TiposPlatos_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'tipoPlatos'],
    ] as const,
    [AppPermissions.TiposPlatos_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'tipoPlatos'],
    ] as const,
    [AppPermissions.Exclusiones_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'exclusiones'],
    ] as const,
    [AppPermissions.Exclusiones_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'exclusiones'],
    ] as const,
    [AppPermissions.PreparacionesCulinarias_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'preparaciones'],
    ] as const,
    [AppPermissions.PreparacionesCulinarias_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'preparaciones'],
    ] as const,
    [AppPermissions.UnidadesMedida_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'unidadesMedida'],
    ] as const,
    [AppPermissions.UnidadesMedida_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'unidadesMedida'],
    ] as const,
    [AppPermissions.Nutrientes_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'nutrientes'],
    ] as const,
    [AppPermissions.Nutrientes_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'nutrientes'],
    ] as const,
    [AppPermissions.ServicioPeriodos_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'temporadas'],
    ] as const,
    [AppPermissions.ServicioPeriodos_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'temporadas'],
    ] as const,
    [AppPermissions.Langs_Acceso]: [
        ['view', 'configuracion'],
        ['read', 'langs'],
    ] as const,
    [AppPermissions.Langs_Admin]: [
        ['view', 'configuracion'],
        ['manage', 'langs'],
    ] as const,
    [AppPermissions.Usuarios_Admin]: [
        ['read', 'security'],
        ['manage', 'users'],
    ] as const,
    [AppPermissions.Usuarios_Acceso]: [
        ['read', 'security'],
        ['read', 'users'],
    ] as const,
    [AppPermissions.Roles_Admin]: [
        ['read', 'security'],
        ['manage', 'roles'],
    ] as const,
    [AppPermissions.Roles_Acceso]: [
        ['read', 'security'],
        ['read', 'roles'],
    ] as const,
    [AppPermissions.Vendors_Admin]: [
        ['manage', 'vendors'],
        ['read', 'procurementCatalogs'],
    ] as const,
    [AppPermissions.VendorItems_Admin]: [['manage', 'vendorItems']] as const,
    [AppPermissions.Vendors_Read]: [
        ['read', 'vendors'],
        ['read', 'procurementCatalogs'],
    ] as const,
    [AppPermissions.ProcurementCatalogs_Read]: [
        ['read', 'vendors'],
        ['read', 'procurementCatalogs'],
    ] as const,
    [AppPermissions.ProcurementCatalogs_Admin_Global]: [
        ['read', 'vendors'],
        ['manage', 'procurementCatalogs'],
        ['manage', 'globalProcurementCatalogs'],
    ] as const,
    [AppPermissions.ProcurementCatalogs_Admin_Local]: [
        ['read', 'vendors'],
        ['manage', 'procurementCatalogs'],
        ['manage', 'localProcurementCatalogs'],
    ] as const,
    [AppPermissions.Purchases_Read]: [
        ['read', 'purchaseRequisitions'],
        ['read', 'purchaseOrders'],
        ['read', 'purchaseReceipts'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.PurchaseRequisitions_Admin]: [
        ['manage', 'purchaseRequisitions'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.PurchaseOrders_Admin]: [
        ['manage', 'purchaseOrders'],
        ['read', 'purchaseRequisitions'],
        ['read', 'purchaseReceipts'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.PurchaseReceipts_Admin]: [
        ['manage', 'purchaseReceipts'],
        ['read', 'purchaseRequisitions'],
        ['read', 'purchaseOrders'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.PurchaseReceipts_Profiles_Admin]: [['manage', 'purchaseReceiptsProfiles']] as const,
    [AppPermissions.ConfirmedPurchaseReceipts_Admin]: [
        ['manage', 'confirmedPurchaseReceipts'],
        ['manage', 'purchaseReceipts'],
        ['read', 'purchaseRequisitions'],
        ['read', 'purchaseOrders'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.ReceiptSettings_Admin]: [['manage', 'receiptSettings']] as const,
    [AppPermissions.Taxes_Admin]: [
        ['manage', 'taxes'],
        ['view', 'configuracion'],
    ] as const,
    [AppPermissions.Taxes_Read]: [
        ['read', 'taxes'],
        ['view', 'configuracion'],
    ] as const,
    [AppPermissions.PhysicalCounts_Admin]: [
        ['manage', 'physicalCounts'],
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.PhysicalCounts_Read]: [
        ['read', 'physicalCounts'],
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.InventoryAdjustmentReasons_Admin]: [
        ['manage', 'inventoryAdjustmentReasons'],
        ['view', 'configuracion'],
    ] as const,
    [AppPermissions.InventoryAdjustmentReasons_Read]: [
        ['read', 'inventoryAdjustmentReasons'],
        ['view', 'configuracion'],
    ] as const,
    [AppPermissions.InventoryAdjustments_Admin]: [
        ['manage', 'inventoryAdjustments'],
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.InventoryAdjustments_Read]: [
        ['read', 'inventoryAdjustments'],
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.InventoryTransfers_Admin]: [
        ['manage', 'inventoryTransfers'],
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.InventoryTransfers_Read]: [
        ['read', 'inventoryTransfers'],
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.Inventory_Read]: [
        ['read', 'inventory'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.MerchandiseReturnReasons_Read]: [
        ['read', 'merchandiseReturnReasons'],
        ['view', 'configuracion'],
    ] as const,
    [AppPermissions.MerchandiseReturnReasons_Admin]: [
        ['manage', 'merchandiseReturnReasons'],
        ['view', 'configuracion'],
    ] as const,
    [AppPermissions.ReturnsToVendor_Admin]: [
        ['manage', 'returnsToVendor'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.ReturnsToVendor_Read]: [
        ['read', 'returnsToVendor'],
        ['view', 'werehouse'],
    ] as const,
    [AppPermissions.PurchaseTemplates_Admin]: [
        ['manage', 'purchaseTemplates'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.PurchaseTemplates_Read]: [
        ['read', 'purchaseTemplates'],
        ['view', 'purchases'],
    ] as const,
    [AppPermissions.ProductionOrders_Read]: [
        ['read', 'productionOrders'],
        ['view', 'production'],
    ] as const,
    [AppPermissions.ProductionOrders_Work]: [
        ['read', 'productionOrders'],
        ['work', 'productionOrders'],
        ['view', 'production'],
    ] as const,
    [AppPermissions.ProductionOrders_Admin]: [
        ['manage', 'productionOrders'],
        ['view', 'production'],
    ] as const,
    [AppPermissions.ControlParameters_Admin]: [
        ['manage', 'controlParameters'],
        ['view', 'haccp'],
    ] as const,
    [AppPermissions.ControlParameters_Read]: [
        ['read', 'controlParameters'],
        ['view', 'haccp'],
    ] as const,
    [AppPermissions.InspectionPlans_Admin]: [
        ['manage', 'inspectionPlans'],
        ['view', 'haccp'],
    ] as const,
    [AppPermissions.InspectionPlans_Read]: [
        ['read', 'inspectionPlans'],
        ['view', 'haccp'],
    ] as const,
    [AppPermissions.HaccpIssues_Admin]: [
        ['manage', 'haccpIssues'],
        ['view', 'haccp'],
    ] as const,
    [AppPermissions.HaccpIssues_Read]: [
        ['read', 'haccpIssues'],
        ['view', 'haccp'],
    ] as const,
    [AppPermissions.DietPrescription_Admin]: [['manage', 'dietPrescription']] as const,
    [AppPermissions.MealtimePriceList_Admin]: [['manage', 'mealtimePriceList']] as const,
};
