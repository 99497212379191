import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Langs_Acceso, Langs_Admin } from '@/auth/permissions';

const IdiomaList = () => import(/* webpackChunkName: "langs" */ './list/index.vue');

export default {
    path: 'langs',
    name: 'home.langs',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.langs.list' },
    meta: {
        permissions: [Langs_Acceso, Langs_Admin],
        anyPermissionValid: true,
        title: 'langs',
    },
    children: [
        {
            path: '',
            name: 'home.langs.list',
            component: IdiomaList,
        },
    ],
} as RouteConfig;
