import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { TiposPlatos_Acceso, TiposPlatos_Admin } from '@/auth/permissions';

const TipoPlatoList = () => import(/* webpackChunkName: "tipo-platos" */ './list/index.vue');

export default {
    path: 'tipo-platos',
    name: 'home.tipoPlatos',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.tipoPlatos.list' },
    meta: {
        permissions: [TiposPlatos_Acceso, TiposPlatos_Admin],
        anyPermissionValid: true,
        title: 'tipoPlatos',
    },
    children: [
        {
            path: '',
            name: 'home.tipoPlatos.list',
            component: TipoPlatoList,
        },
    ],
} as RouteConfig;
