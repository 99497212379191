import { ModoSeleccion } from './modo-seleccion';

export interface TipoPlatoData {
    id: number;
    nombre: string;
    orden: number;
    modoSeleccion: ModoSeleccion;
    isDeleted: boolean;
}

export class TipoPlato {
    public id: number;
    public nombre: string;
    public orden: number;
    public modoSeleccion: ModoSeleccion;
    public isDeleted: boolean;

    public constructor(data: TipoPlatoData = {} as TipoPlatoData) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.orden = data.orden;
        this.modoSeleccion = data.modoSeleccion;
        this.isDeleted = data.isDeleted;
    }
}
