import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import * as permissions from './permissions';
import userManager from './user-manager';

import TipoServicio from '../servicios/models/tipo-servicio';
import { Limit, CocinaLimit, CentroLimit, ServicioLimit } from '@/security/users/models/limit';
import { PaperKind } from '@/centros/models/paper-kind';
import { Facility } from '@/centros/models/centro';
import { Kitchen } from '@/cocinas/models/cocina';
import { PointOfCare } from '@/servicios/models/servicio';
import { FacilityDeliveryTime } from '@/centros/models/hora-servicio';

const appPermissions: string[] = Object.values(permissions);

export default class LoggedUser {
    public centros: Facility[] = [];
    public cocinas: Kitchen[] = [];
    public permissions: string[] = [];
    public limits: Limit = new Limit();
    public locale = '';
    public lastLocation: {
        diners?: { centroId: string; servicioId: string };
    } = {};

    public constructor(private user: Oidc.User) {}

    public get servicios() {
        return _.flatMap(this.centros, (c) => c.servicios);
    }

    public get expired(): boolean {
        return !!this.user.expired;
    }

    public get name(): string | null {
        if (!this.user.profile) {
            return null;
        }

        let { name, given_name, family_name } = this.user.profile;

        if (name && Array.isArray(name)) {
            name = name[0];
        }

        if (given_name && Array.isArray(given_name)) {
            given_name = given_name[0];
        }

        if (family_name && Array.isArray(family_name)) {
            family_name = family_name[0];
        }

        if (name) {
            return name;
        }

        if (given_name) {
            return `${given_name} ${family_name || ''}`.trim();
        }

        return null;
    }

    public get userName(): string {
        if (!this.user.profile) {
            return '';
        }

        const { preferred_username } = this.user.profile;

        return preferred_username as string;
    }

    public get subjectId(): number {
        return parseInt(this.user.profile.sub, 10);
    }

    public get profileName(): string {
        return this.user.profile.name as string;
    }

    public get authorizationHeader(): string {
        return `Bearer ${this.user.access_token}`;
    }

    public get centrosConfigurados(): Facility[] {
        return this.centros
            .map(
                (c) =>
                    ({
                        ...c,
                        servicios: c.servicios.filter((s: any) => s.tipo !== TipoServicio.NoEspecificado),
                    }) as Facility,
            )
            .filter((c) => c.servicios.length > 0);
    }

    public canAccess(...requestedPermissions: string[]): boolean {
        return this.hasPermissions(requestedPermissions);
    }

    public canAccessAny(...requestedPermissions: string[]): boolean {
        return this.hasPermissions(requestedPermissions, false);
    }

    public logout(): void {
        userManager.signoutRedirect();
    }

    public async initAccesos() {
        interface MeResponse {
            permissions: string[];
            cocinas: Array<{
                id: number;
                nombre: string;
                codigo: string;
                timeZone: string;
                isDeleted: boolean;
                reportSettings: {
                    production: {
                        limitPreparacionIds: number[];
                        detailedExclusionIds: number[];
                    };
                };
            }>;
            centros: Array<{
                id: string;
                nombre: string;
                cocinaId?: number;
                diasTrabajo: number;
                timeZone: string;
                paperKind: PaperKind;
                cardsPerPage: number;
                createdOn?: string;
                horaServicios: Array<{
                    ingestaId: number;
                    hora?: number;
                    deliveryStartTime?: string;
                }>;
                servicios: Array<{
                    id: string;
                    nombre: string;
                    tipo: TipoServicio;
                    restringirDietas: boolean;
                    admiteAdjuntos: boolean;
                    gestionarAdjuntos: boolean;
                    maxAdjuntos?: number;
                    createdOn?: string;
                }>;
            }>;
            limits: {
                cocinas: Array<{
                    id: number;
                    centros: Array<{
                        id: string;
                        servicioIds: string[];
                    }>;
                }>;
            };
            locale: string;
            lastLocation?: typeof LoggedUser.prototype.lastLocation;
        }
        const response = await axios.get<MeResponse>('me', {
            headers: {
                Authorization: this.authorizationHeader,
            },
        });

        this.cocinas = response.data.cocinas;

        this.centros = response.data.centros.map((c) => {
            return {
                ...c,
                cocina: this.cocinas.find((co) => co.id === c.cocinaId),
                horaServicios: c.horaServicios.map(
                    (h) =>
                        ({
                            ...h,
                            hora: typeof h.hora === 'number' ? moment().startOf('day').minutes(h.hora) : h.hora,
                            deliveryStartTime: h.deliveryStartTime ? moment(h.deliveryStartTime, 'HH:mm:ss') : null,
                            get minutes() {
                                if (!this.hora) {
                                    return null;
                                }

                                return this.hora.hours() * 60 + this.hora.minutes();
                            },
                        }) as FacilityDeliveryTime,
                ),
                servicios: c.servicios.map(
                    (s) =>
                        ({
                            ...s,
                            centroId: c.id,
                        }) as PointOfCare,
                ),
            } as Facility;
        });

        this.permissions = response.data.permissions;

        this.limits = new Limit({
            cocinas: response.data.limits.cocinas
                .map((co) => {
                    const cocina = this.cocinas.find((coc) => coc.id === co.id);
                    if (!cocina) {
                        return null;
                    }

                    return new CocinaLimit({
                        id: co.id,
                        nombre: cocina.nombre,
                        centros: co.centros
                            .map((ce) => {
                                const centro = this.centros.find((cent) => cent.id === ce.id);

                                if (!centro) {
                                    return null;
                                }

                                return new CentroLimit({
                                    id: ce.id,
                                    nombre: centro.nombre,
                                    servicios: ce.servicioIds
                                        .map((id) => {
                                            const servicio = centro.servicios.find((se) => se.id === id);

                                            if (!servicio) {
                                                return null;
                                            }

                                            return new ServicioLimit({
                                                id,
                                                nombre: servicio.nombre,
                                            });
                                        })
                                        .filter((s): s is ServicioLimit => !!s),
                                });
                            })
                            .filter((ce): ce is CentroLimit => !!ce),
                    });
                })
                .filter((co): co is CocinaLimit => !!co),
        });

        this.locale = response.data.locale;
        this.lastLocation = response.data.lastLocation ?? {};
    }

    private hasPermissions(requestedPermissions: string[], allRequired = true): boolean {
        if (requestedPermissions.length === 0) {
            return true;
        }

        const invalidPermission = requestedPermissions.find((p) => !appPermissions.includes(p));
        if (invalidPermission) {
            throw new Error(`Invalid permission ${invalidPermission}`);
        }

        if (this.expired || !this.user.profile) {
            return false;
        }

        const missing = requestedPermissions.filter((p) => !this.permissions.includes(p));

        if (missing.length === 0 || (!allRequired && missing.length < requestedPermissions.length)) {
            return true;
        }

        return false;
    }
}
