import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ConfirmModal extends Vue {
    @Prop({ type: String }) public title: string;
    @Prop({ type: String }) public message: string;
    @Prop({ type: String, default: 'primary' }) public color: string;
    @Prop({ type: Boolean, default: false }) public hideCancel: string;
    @Prop({ type: Boolean, default: false }) public hideOk: string;

    @Prop({
        type: String,
        default() {
            const vm: ConfirmModal = this as ConfirmModal;
            return vm.$t('si');
        },
    })
    public okText: string;

    @Prop({
        type: String,
        default() {
            const vm: ConfirmModal = this as ConfirmModal;
            return vm.$t('no');
        },
    })
    public cancelText: string;

    @Prop({
        type: String,
        default: 'default',
    })
    public size: string;

    public show = true;
    public result = false;

    public mounted() {
        document.body.appendChild(this.$el);
    }

    public destroyed() {
        document.body.removeChild(this.$el);
    }

    public close(result = false) {
        this.result = result;
        this.show = false;
    }

    public onAfterLeave() {
        this.$emit('close', this.result);
    }
}
