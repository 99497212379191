import axios from 'axios';
import { Complejo } from '@/complejos/models/complejo';

export async function getComplejos() {
    const response = await axios.get('complejos');
    return [...response.data.complejos].map((c) => new Complejo(c));
}

export async function getComplejo(complejoId: number) {
    const response = await axios.get(`complejos/${complejoId}`);
    return new Complejo(response.data.complejo);
}

export async function updateComplejo(complejoId: number, data: { nombre?: string; isDeleted?: boolean; imagen?: File | null }) {
    // Se usa FormData para poder mandar la imagen en una sola petición
    const formData = new FormData();
    if (data.nombre !== undefined) {
        formData.append('nombre', data.nombre);
    }

    if (data.isDeleted !== undefined) {
        formData.append('isDeleted', data.isDeleted.toString());
    }

    if (data.imagen !== undefined) {
        formData.append('img', data.imagen ? data.imagen : 'null');
    }

    await axios.patch(`complejos/${complejoId}`, formData);
}

export async function createComplejo(data: { nombre: string; imagen?: File }) {
    // Se usa FormData para poder mandar la imagen en una sola petición
    const formData = new FormData();
    formData.append('nombre', data.nombre);

    if (data.imagen !== undefined) {
        formData.append('imagen', data.imagen);
    }

    await axios.post('complejos', formData);
}

export async function deleteComplejo(complejoId: number) {
    await axios.delete(`complejos/${complejoId}`);
}
