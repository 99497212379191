export class Culture {
    public id: string;
    public displayName: string;
    public nativeName: string;
    public isDeleted: boolean;

    public constructor(data: Culture) {
        this.id = data.id;
        this.displayName = data.displayName;
        this.nativeName = data.nativeName;
        this.isDeleted = data.isDeleted;
    }
}
