import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { PreparacionesCulinarias_Acceso, PreparacionesCulinarias_Admin } from '@/auth/permissions';

const PreparacionesList = () => import(/* webpackChunkName: "preparaciones" */ './list/index.vue');
const PreparacionDetail = () => import(/* webpackChunkName: "preparaciones" */ './detail/index.vue');

export default {
    path: 'preparaciones',
    name: 'home.preparaciones',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.preparaciones.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "preparaciones" */ './store');
        next();
    },
    meta: {
        permissions: [PreparacionesCulinarias_Acceso, PreparacionesCulinarias_Admin],
        anyPermissionValid: true,
        title: 'preparaciones',
    },
    children: [
        {
            path: '',
            name: 'home.preparaciones.list',
            component: PreparacionesList,
        },
        {
            path: ':preparacionId',
            name: 'home.preparaciones.detail',
            component: PreparacionDetail,
        },
    ],
} as RouteConfig;
