import axios from 'axios';
import { TipoPlato } from '../models/tipo-plato';
import { ModoSeleccion } from '../models/modo-seleccion';

export async function getTipoPlatos({ soloActivos = true }: { soloActivos?: boolean } = {}) {
    const response = await axios.get('tipo-platos', {
        params: {
            soloActivos,
        },
    });

    if (!Array.isArray(response.data.tipos)) {
        throw new Error('Expected Array');
    }

    return [...response.data.tipos].map((t) => new TipoPlato(t));
}

export async function saveSortedTipoPlatos(tipos: Array<{ id: number; orden: number }>) {
    await axios.patch('tipo-platos', { tipos });
}

export async function createTipoPlato({ nombre, orden, modoSeleccion }: { nombre: string; orden: number; modoSeleccion: ModoSeleccion }) {
    const response = await axios.post('tipo-platos', { nombre, orden, modoSeleccion });
    return new TipoPlato(response.data.tipo);
}

export async function updateTipoPlato(
    tipoPlatoId: number,
    { nombre, modoSeleccion, isDeleted }: { nombre?: string; modoSeleccion: ModoSeleccion; isDeleted?: boolean },
) {
    await axios.patch(`tipo-platos/${tipoPlatoId}`, { nombre, modoSeleccion, isDeleted });
}
