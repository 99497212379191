import moment, { MomentInput } from 'moment';

export class Temporada {
    public id: number;
    public nombre: string;
    public mesDesde: number;
    public diaDesde: number;
    public mesHasta: number;
    public diaHasta: number;
    public isDeleted: boolean;

    public constructor(data: Temporada = {} as Temporada) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.mesDesde = data.mesDesde;
        this.diaDesde = data.diaDesde;
        this.mesHasta = data.mesHasta;
        this.diaHasta = data.diaHasta;
        this.isDeleted = data.isDeleted;
    }

    public containsFecha(fechaInp: MomentInput) {
        const fecha = moment(fechaInp);
        if (!fecha.isValid()) {
            throw new Error('Invalid date');
        }

        const desde = moment({
            day: this.diaDesde,
            month: this.mesDesde - 1,
            year:
                fecha.year() +
                (this.mesHasta < this.mesDesde || (this.mesHasta === this.mesDesde && this.diaHasta < this.diaDesde)
                    ? fecha.month() + 1 < this.mesDesde || (fecha.month() + 1 === this.mesDesde && fecha.day() < this.diaDesde)
                        ? -1
                        : 0
                    : 0),
        });

        const hasta = moment({
            day: this.diaHasta,
            month: this.mesHasta - 1,
            year:
                fecha.year() +
                (this.mesHasta < this.mesDesde || (this.mesHasta === this.mesDesde && this.diaHasta < this.diaDesde)
                    ? fecha.month() + 1 < this.mesDesde || (fecha.month() + 1 === this.mesDesde && fecha.day() < this.diaDesde)
                        ? 0
                        : 1
                    : 0),
        });

        return (fecha >= desde && fecha <= hasta) || (fecha >= desde.clone().add(1, 'year') && fecha <= hasta.clone().add(1, 'year'));
    }
}
