import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Usuarios_Acceso, Usuarios_Admin } from '../../auth/permissions';

const ListUsers = (r: any) => import(/* webpackChunkName: "security-users" */ './list/index.vue').then(r);
const UserDetail = (r: any) => import(/* webpackChunkName: "security-users" */ './detail/index.vue').then(r);

export default {
    path: 'security/users',
    name: 'home.security.users',
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "security-users" */ './store');
        next();
    },
    component: { render: () => h('router-view') },
    redirect: { name: 'home.security.users.list' },
    meta: {
        permissions: [Usuarios_Acceso, Usuarios_Admin],
        anyPermissionValid: true,
        title: 'users',
    },
    children: [
        {
            path: '',
            name: 'home.security.users.list',
            component: ListUsers,
        },
        {
            path: ':userId',
            name: 'home.security.users.detail',
            component: UserDetail,
            props: (r) => ({
                ...r.params,
                userId: parseInt(r.params.userId, 10),
            }),
        },
    ],
} as RouteConfig;
