import { h } from 'vue';
import { Nutrientes_Acceso, Nutrientes_Admin } from '@/auth/permissions';
import { RouteConfig } from 'vue-router';

const NutrientsGridList = () => import(/* webpackChunkName: "nutrients-grid-list" */ './list/index.vue');
const NutrientGridItemList = () => import(/* webpackChunkName: "nutrients-grid-detail" */ './detail/index.vue');

export default {
    path: 'nutrients/grids',
    name: 'home.nutrients.grids',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.nutrients.grids.list' },
    meta: {
        permissions: [Nutrientes_Acceso, Nutrientes_Admin],
        title: 'tabTitles.nutrientsGrid.list',
    },
    children: [
        {
            path: '',
            name: 'home.nutrients.grids.list',
            component: NutrientsGridList,
        },
        {
            path: ':gridId',
            name: 'home.nutrients.grids.detail',
            props: (r) => ({ ...r.params, gridId: Number.parseInt(r.params.gridId, 10) }),
            component: NutrientGridItemList,
        },
    ],
} as RouteConfig;
