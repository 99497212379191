import Vue from 'vue';

const isActiveElementAnInput = () => document.activeElement && document.activeElement.tagName === 'INPUT';

Vue.directive('focus', {
    inserted(el, binding) {
        if (binding.value && !isActiveElementAnInput()) {
            el.focus();
        } else {
            el.blur();
        }
    },
    update(el, binding) {
        if (binding.value && !isActiveElementAnInput()) {
            Vue.nextTick(() => el.focus());
        } else {
            Vue.nextTick(() => el.blur());
        }
    },
});
