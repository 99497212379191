import { UnidadMedida } from '../../unidades-medida/models/unidad-medida';
import { Nutriente } from '../../nutrientes/models/nutriente';

export class Alimento {
    public id: number;
    public codigo: string;
    public foodEx?: string;
    public nombre: string;
    public porcionComestible: number;
    public fuenteId: number;
    public valoresNutricionales: ValorNutricional[];

    public constructor(data: Alimento) {
        this.id = data.id;
        this.codigo = data.codigo;
        this.foodEx = data.foodEx;
        this.nombre = data.nombre;
        this.porcionComestible = data.porcionComestible;
        this.fuenteId = data.fuenteId;
        this.valoresNutricionales = data.valoresNutricionales || [];
    }
}

export class ValorNutricional {
    public id: number;
    public nutriente: Nutriente;
    public unidadMedida: UnidadMedida;
    public unidadMatriz: UnidadMatriz;
    public cantidad: number;

    public constructor(data: ValorNutricional) {
        this.id = data.id;
        this.nutriente = data.nutriente;
        this.unidadMedida = data.unidadMedida;
        this.unidadMatriz = data.unidadMatriz;
        this.cantidad = data.cantidad;
    }
}

export class UnidadMatriz {
    public id: number;
    public nombre: string;
    public codigo: string;
    public usaPorcionComestible: boolean;
    public cantidad?: number;
    public unidadMedida: UnidadMedida;
    public isDeleted: boolean;

    public constructor(data: UnidadMatriz) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.codigo = data.codigo;
        this.usaPorcionComestible = data.usaPorcionComestible;
        this.cantidad = data.cantidad;
        this.unidadMedida = data.unidadMedida;
        this.isDeleted = data.isDeleted;
    }
}

export class Fuente {
    public id: number;
    public nombre: string;

    public constructor(data: Fuente) {
        this.id = data.id;
        this.nombre = data.nombre;
    }
}
