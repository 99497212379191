import axios from 'axios';
import { Temporada } from '../models/temporada';

export async function getTemporadas({ soloActivos = true }: { soloActivos?: boolean } = {}) {
    const response = await axios.get('temporadas', { params: { soloActivos } });

    return [...response.data.periodos].map((p) => new Temporada(p));
}

export async function updateTemporada(
    temporadaId: number,
    {
        nombre,
        mesDesde,
        diaDesde,
        mesHasta,
        diaHasta,
        isDeleted,
    }: {
        nombre?: string;
        mesDesde?: number;
        diaDesde?: number;
        mesHasta?: number;
        diaHasta?: number;
        isDeleted?: boolean;
    },
) {
    await axios.patch(`temporadas/${temporadaId}`, {
        nombre,
        mesDesde,
        diaDesde,
        mesHasta,
        diaHasta,
        isDeleted,
    });
}

export async function createTemporada({
    nombre,
    mesDesde,
    diaDesde,
    mesHasta,
    diaHasta,
}: {
    nombre: string;
    mesDesde: number;
    diaDesde: number;
    mesHasta: number;
    diaHasta: number;
}) {
    const response = await axios.post(`temporadas`, {
        nombre,
        mesDesde,
        diaDesde,
        mesHasta,
        diaHasta,
    });
    return new Temporada(response.data.temporada);
}
