import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { ServicioPeriodos_Acceso, ServicioPeriodos_Admin } from '@/auth/permissions';

const TemporadaList = () => import(/* webpackChunkName: "temporadas" */ './list/index.vue');

export default {
    path: 'temporadas',
    name: 'home.temporadas',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.temporadas.list' },
    meta: {
        permissions: [ServicioPeriodos_Acceso, ServicioPeriodos_Admin],
        anyPermissionValid: true,
        title: 'temporadas',
    },
    children: [
        {
            path: '',
            name: 'home.temporadas.list',
            component: TemporadaList,
        },
    ],
} as RouteConfig;
