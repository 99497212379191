import { UnidadMedida } from '../../unidades-medida/models/unidad-medida';

export class Conversion {
    public unidadMedida: UnidadMedida;
    public factorConversion: number;

    public constructor(data: Conversion) {
        this.unidadMedida = data.unidadMedida;
        this.factorConversion = data.factorConversion;
    }
}
