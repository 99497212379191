import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Exclusiones_Acceso, Exclusiones_Admin } from '@/auth/permissions';

const ExclusionList = () => import(/* webpackChunkName: "exclusiones" */ './list/index.vue');
const ExclusionDetail = () => import(/* webpackChunkName: "exclusiones" */ './detail/index.vue');

export default {
    path: 'exclusiones',
    name: 'home.exclusiones',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.exclusiones.list' },
    meta: {
        permissions: [Exclusiones_Acceso, Exclusiones_Admin],
        anyPermissionValid: true,
        title: 'exclusiones',
    },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "exclusiones" */ './store');
        next();
    },
    children: [
        {
            path: '',
            name: 'home.exclusiones.list',
            component: ExclusionList,
        },
        {
            path: ':exclusionId',
            name: 'home.exclusiones.detail',
            component: ExclusionDetail,
        },
    ],
} as RouteConfig;
