import { Payload } from 'vuex';

export class LoadExclusiones implements Payload {
    public type = 'loadExclusiones';
}

export class LoadPreparaciones implements Payload {
    public type = 'loadPreparaciones';
}

export class LoadCultures implements Payload {
    public type = 'loadCultures';
}

export class LoadMotivosCambioDieta implements Payload {
    public type = 'loadMotivosCambioDieta';
}

export class LoadUnidadesMedida implements Payload {
    public type = 'loadUnidadesMedida';
}

export class LoadUnidadesMatriz implements Payload {
    public type = 'loadUnidadesMatriz';
}

export class LoadFuentes implements Payload {
    public type = 'loadFuentes';
}

export class LoadDietas implements Payload {
    public type = 'loadDietas';
}

export class LoadIngestas implements Payload {
    public type = 'loadIngestas';
}

export class LoadTemporadas implements Payload {
    public type = 'loadTemporadas';
}

export class LoadTipoPlatos implements Payload {
    public type = 'loadTipoPlatos';
}

export class LoadComplejos implements Payload {
    public type = 'loadComplejos';
}

export class ChangeUserLocale implements Payload {
    public type = 'changeUserLocale';

    constructor(public locale: string) {}
}

export class LoadNutrientGrids implements Payload {
    public type = 'loadNutrientGrids';
}
