import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';

export async function initIntl() {
    if (shouldPolyfillGetCanonicalLocales()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }

    if (shouldPolyfillLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }

    if (
        shouldPolyfillPluralRules('en') ||
        shouldPolyfillPluralRules('es') ||
        shouldPolyfillPluralRules('ca') ||
        shouldPolyfillPluralRules('pt') ||
        shouldPolyfillPluralRules('gl')
    ) {
        await import('@formatjs/intl-pluralrules/polyfill-force');

        await import('@formatjs/intl-pluralrules/locale-data/en');
        await import('@formatjs/intl-pluralrules/locale-data/es');
        await import('@formatjs/intl-pluralrules/locale-data/ca');
        await import('@formatjs/intl-pluralrules/locale-data/pt');
        await import('@formatjs/intl-pluralrules/locale-data/gl');
    }

    if (
        shouldPolyfillNumberFormat('en') ||
        shouldPolyfillNumberFormat('es') ||
        shouldPolyfillNumberFormat('ca') ||
        shouldPolyfillNumberFormat('pt') ||
        shouldPolyfillNumberFormat('gl')
    ) {
        await import('@formatjs/intl-numberformat/polyfill-force');

        await import('@formatjs/intl-numberformat/locale-data/en');
        await import('@formatjs/intl-numberformat/locale-data/es');
        await import('@formatjs/intl-numberformat/locale-data/ca');
        await import('@formatjs/intl-numberformat/locale-data/pt');
        await import('@formatjs/intl-numberformat/locale-data/gl');
    }

    if (
        shouldPolyfillDateTimeFormat('en') ||
        shouldPolyfillDateTimeFormat('es') ||
        shouldPolyfillDateTimeFormat('ca') ||
        shouldPolyfillDateTimeFormat('gl') ||
        shouldPolyfillDateTimeFormat('pt')
    ) {
        await import('@formatjs/intl-datetimeformat/polyfill-force');
        await import('@formatjs/intl-datetimeformat/add-golden-tz');

        await import('@formatjs/intl-datetimeformat/locale-data/en');
        await import('@formatjs/intl-datetimeformat/locale-data/es');
        await import('@formatjs/intl-datetimeformat/locale-data/ca');
        await import('@formatjs/intl-datetimeformat/locale-data/gl');
        await import('@formatjs/intl-datetimeformat/locale-data/pt');
    }

    if (
        shouldPolyfillDisplayNames('en') ||
        shouldPolyfillDisplayNames('es') ||
        shouldPolyfillDisplayNames('ca') ||
        shouldPolyfillDisplayNames('gl') ||
        shouldPolyfillDisplayNames('pt')
    ) {
        await import('@formatjs/intl-displaynames/polyfill-force');

        await import('@formatjs/intl-displaynames/locale-data/en');
        await import('@formatjs/intl-displaynames/locale-data/es');
        await import('@formatjs/intl-displaynames/locale-data/ca');
        await import('@formatjs/intl-displaynames/locale-data/gl');
        await import('@formatjs/intl-displaynames/locale-data/pt');
    }
}
