import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Ingestas_Acceso, Ingestas_Admin } from '@/auth/permissions';

const IngestasList = () => import(/* webpackChunkName: "ingestas" */ './list/index.vue');
const PriceList = () => import(/* webpackChunkName: "ingestas" */ './price-list/index.vue');

export default {
    path: 'ingestas',
    name: 'home.ingestas',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.ingestas.list' },
    async beforeEnter(to, from, next) {
        // await import(/* webpackChunkName: "ingestas" */ './store');
        next();
    },
    meta: {
        permissions: [Ingestas_Acceso, Ingestas_Admin],
        anyPermissionValid: true,
        title: 'ingestas',
    },
    children: [
        {
            path: '',
            name: 'home.ingestas.list',
            component: IngestasList,
        },
        {
            path: 'price-list',
            name: 'home.ingestas.price-list',
            component: PriceList,
        },
    ],
} as RouteConfig;
