import { GetterTree } from 'vuex';

import { CoquusState } from './';

export default {
    exclusionesActivas(state) {
        return state.cache.exclusiones.filter((e) => !e.isDeleted);
    },
    preparacionesActivas(state) {
        return state.cache.preparaciones.filter((p) => !p.isDeleted);
    },
    motivosCambioDietaActivos(state) {
        return state.cache.motivosCambioDieta.filter((p) => !p.isDeleted);
    },
    unidadesMedidaActivas(state) {
        return state.cache.unidadesMedida.filter((p) => !p.isDeleted);
    },
    unidadesMatrizActivas(state) {
        return state.cache.unidadesMatriz.filter((p) => !p.isDeleted);
    },
    dietasActivas(state) {
        return state.cache.dietas.filter((d) => !d.isDeleted);
    },
    ingestasActivas(state) {
        return state.cache.ingestas.filter((i) => !i.isDeleted);
    },
    temporadasActivas(state) {
        return state.cache.temporadas.filter((i) => !i.isDeleted);
    },
    tipoPlatosActivos(state) {
        return state.cache.tipoPlatos.filter((tp) => !tp.isDeleted);
    },
    complejosActivos(state) {
        return state.cache.complejos.filter((c) => !c.isDeleted);
    },
} as GetterTree<CoquusState, CoquusState>;
