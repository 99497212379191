<template>
    <div ref="root" class="breadcrum-container">
        <div class="coquus-breadcrum">
            <template v-for="(item, idx) of visibleItems">
                <router-link v-if="typeof item === 'object'" :to="item" class="menu-button" :key="`link-${idx}`">
                    <TextEllipsis :text="item.title" />
                </router-link>
                <div v-else class="menu-button disabled" :key="`text-${idx}`">
                    <TextEllipsis :text="item" />
                </div>
                <div v-if="idx < items.length - 1" class="menu-separator" :key="`separator-${idx}`">
                    <icon :icon="faChevronRight" size="xs" />
                </div>
            </template>
        </div>
        <!-- Este div esta para saber si el breadcrum va a tener scroll, por eso se usa `items` -->
        <div ref="placeholder" class="coquus-breadcrum coquus-breadcrum--placeholder">
            <template v-for="(item, idx) of items">
                <router-link v-if="typeof item === 'object'" :to="item" class="menu-button" tabindex="-1" :key="`link-${idx}`">
                    <TextEllipsis :text="item.title" />
                </router-link>
                <div v-else class="menu-button disabled" :key="`text-${idx}`">
                    <TextEllipsis :text="item" />
                </div>
                <div v-if="idx < items.length - 1" class="menu-separator" :key="`separator-${idx}`">
                    <icon :icon="faChevronRight" size="xs" />
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { array } from 'vue-types';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import type { BreadcrumItem } from './breadcrum-item';

export default defineComponent({
    name: 'CoquusBreadcrum',
    props: {
        items: array<BreadcrumItem>().isRequired,
    },
    setup(props) {
        const root = ref<HTMLElement>();
        const placeholder = ref<HTMLElement>();
        const hasScroll = ref(false);

        const resizeObserver = new ResizeObserver(([entry]) => {
            if (!placeholder.value) {
                return;
            }

            hasScroll.value = placeholder.value.clientWidth > entry.target.clientWidth;
            if (hasScroll.value) {
                entry.target.scrollTo({ left: entry.target.scrollWidth });
            }
        });

        const visibleItems = computed(() => {
            if (!hasScroll.value) {
                return props.items;
            }

            const firstLinkIdx = props.items.findIndex((item) => typeof item === 'object');
            const lastLinkIdx = props.items.findLastIndex((item) => typeof item === 'object');

            const result = [...props.items.slice(0, firstLinkIdx + 1), '...', ...props.items.slice(lastLinkIdx)];

            // Si el resultado es mas largo que el original, no se hace nada
            if (result.length > props.items.length) {
                return props.items;
            }

            return result;
        });

        onMounted(() => {
            if (!root.value) {
                return;
            }

            let scrollableParent = root.value;
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const styles = window.getComputedStyle(scrollableParent);

                if (styles.overflowX === 'scroll' || styles.overflowX === 'auto') {
                    break;
                }

                if (!scrollableParent.parentElement) {
                    break;
                }

                scrollableParent = scrollableParent.parentElement;
            }

            resizeObserver.observe(scrollableParent);
        });

        onUnmounted(() => {
            resizeObserver.disconnect();
        });

        return {
            faChevronRight,
            root,
            placeholder,
            visibleItems,
        };
    },
});
</script>

<style lang="scss" scoped>
.breadcrum-container {
    height: 100%;
    position: relative;
}

.coquus-breadcrum {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: normal;

    &--placeholder {
        position: fixed;
        top: -100000px;
        left: -100000px;
        visibility: hidden;
        pointer-events: none;
        // El alto no importa
        height: auto;
    }
}

.menu-separator {
    margin: 0 0.25rem;
    line-height: 0;
}
</style>
