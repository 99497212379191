import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Menus_Acceso, Menus_Admin } from '@/auth/permissions';

const MenusCalendar = () => import(/* webpackChunkName: "menus" */ './calendar/index.vue');
const MenuDetail = () => import(/* webpackChunkName: "menus" */ './detail/index.vue');
const CreateMenu = () => import(/* webpackChunkName: "create-menu" */ './create/index.vue');

export default {
    path: 'menus',
    name: 'home.menus',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.menus.calendar' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "menus" */ './store');
        next();
    },
    meta: {
        permissions: [Menus_Acceso, Menus_Admin],
        anyPermissionValid: true,
        title: 'menus',
    },
    children: [
        {
            path: '',
            name: 'home.menus.calendar',
            component: MenusCalendar,
        },
        {
            path: 'create',
            name: 'home.menus.create',
            component: CreateMenu,
            meta: {
                permissions: [Menus_Admin],
                anyPermissionValid: false,
            },
        },
        {
            path: ':menuId',
            name: 'home.menus.detail',
            component: MenuDetail,
        },
    ],
} as RouteConfig;
