import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { elementContains } from '@/utilities/dom/element-contains';
import { getLastTabbable, getFirstTabbable, getNextElement, focusAsync } from '@/utilities/focus';
import { on } from '@/utilities/dom/on';

/**
 * FocusTrapZone is used to trap the focus in any html element. Pressing tab will circle focus within the inner focusable elements of the FocusTrapZone.
 *
 * Original: https://github.com/OfficeDev/office-ui-fabric-react/blob/master/packages/office-ui-fabric-react/src/components/FocusTrapZone/FocusTrapZone.tsx
 */
@Component
export default class FocusTrapZone extends Vue {
    // /**
    //  * Sets the HTMLElement to focus on when exiting the FocusTrapZone.
    //  */
    // @Prop({ type: HTMLElement, default: null })
    // private elementToFocusOnDismiss: HTMLElement | null;
    /**
     * Selector for first focusable item. Ej: .class o #id
     */
    @Prop({ type: String, default: null })
    private firstFocusableSelector: string | null;
    /**
     * Indicates whether focus trap zone should force focus inside the focus trap zone
     */
    @Prop({ type: Boolean, default: true })
    private forceFocusInsideTrap: boolean;
    /**
     * Indicates if this Trap Zone will allow clicks outside the FocusTrapZone
     */
    @Prop({ type: Boolean, default: false })
    private isClickableOutsideFocusTrap: boolean;
    /**
     * Whether to disable the FocusTrapZone's focus trapping behavior.
     */
    @Prop({ type: Boolean, default: false })
    private disabled: boolean;

    private hasFocus = false;
    private disposeFocusHandler: (() => void) | null = null;
    private disposeClickHandler: (() => void) | null = null;

    public onRootFocus(): void {
        this.hasFocus = true;
    }

    public onRootBlur(ev: FocusEvent): void {
        let relatedTarget = ev.relatedTarget;
        if (ev.relatedTarget == null) {
            // In IE11, due to lack of support, event.relatedTarget is always
            // null making every onBlur call to be "outside" of the ComboBox
            // even when it's not. Using document.activeElement is another way
            // for us to be able to get what the relatedTarget without relying
            // on the event
            relatedTarget = document.activeElement as Element;
        }

        if (!elementContains(this.$refs.root as HTMLElement, relatedTarget as HTMLElement)) {
            this.hasFocus = false;
        }
    }

    public onFirstBumperFocus(): void {
        this.onBumperFocus(true);
    }

    public onLastBumperFocus(): void {
        this.onBumperFocus(false);
    }

    private bringFocusIntoZone(): void {
        if (this.disabled) {
            return;
        }

        if (!elementContains(this.$refs.root as HTMLElement, document.activeElement as HTMLElement)) {
            this.focus();
        } else {
            this.hasFocus = true;
        }
    }

    private focus(): void {
        let firstFocusableChild: HTMLElement | null = null;
        if (this.$refs.root && this.$refs.root instanceof HTMLElement) {
            if (this.firstFocusableSelector) {
                firstFocusableChild = this.$refs.root.querySelector(this.firstFocusableSelector);
            }

            if (!firstFocusableChild) {
                firstFocusableChild = getNextElement(this.$refs.root, this.$refs.root.firstChild as HTMLElement, false, false, false, true);
            }
        }

        if (firstFocusableChild) {
            this.hasFocus = true;
            this.focusAsync(firstFocusableChild);
        }
    }

    private focusAsync(element: HTMLElement): void {
        if (!this.isBumper(element)) {
            focusAsync(element);
        }
    }

    private onBumperFocus(isFirstBumper: boolean): void {
        if (this.disabled) {
            return;
        }

        const currentBumper = (isFirstBumper === this.hasFocus ? this.$refs.lastBumper : this.$refs.firstBumper) as HTMLElement;

        if (this.$refs.root && this.$refs.root instanceof HTMLElement) {
            const nextFocusable =
                isFirstBumper === this.hasFocus
                    ? getLastTabbable(this.$refs.root, currentBumper, true, false)
                    : getFirstTabbable(this.$refs.root, currentBumper, true, false);

            if (nextFocusable) {
                if (this.isBumper(nextFocusable)) {
                    // This can happen when FTZ contains no tabbable elements. focus will take care of finding a focusable element in FTZ.
                    this.focus();
                } else {
                    nextFocusable.focus();
                }
            }
        }
    }

    private isBumper(element: HTMLElement): boolean {
        return element === this.$refs.firstBumper || element === this.$refs.lastBumper;
    }

    private forceFocusInTrap(ev: FocusEvent): void {
        if (this.disabled) {
            return;
        }

        const focusedElement = document.activeElement as HTMLElement;

        if (!elementContains(this.$refs.root as HTMLElement, focusedElement)) {
            this.focus();
            this.hasFocus = true; // set focus here since we stop event propagation
            ev.preventDefault();
            ev.stopPropagation();
        }
    }

    private forceClickInTrap(ev: MouseEvent): void {
        if (this.disabled) {
            return;
        }

        const clickedElement = ev.target as HTMLElement;

        if (clickedElement && !elementContains(this.$refs.root as HTMLElement, clickedElement)) {
            this.focus();
            this.hasFocus = true; // set focus here since we stop event propagation
            ev.preventDefault();
            ev.stopPropagation();
        }
    }

    @Watch('forceFocusInsideTrap')
    @Watch('isClickableOutsideFocusTrap')
    protected updateEventHandlers(): void {
        if (this.forceFocusInsideTrap && !this.disposeFocusHandler) {
            this.disposeFocusHandler = on(window, 'focus', this.forceFocusInTrap, true);
        } else if (!this.forceFocusInTrap && this.disposeFocusHandler) {
            this.disposeFocusHandler();
            this.disposeFocusHandler = null;
        }

        if (!this.isClickableOutsideFocusTrap && !this.disposeClickHandler) {
            this.disposeClickHandler = on(window, 'click', this.forceClickInTrap, true);
        } else if (this.isClickableOutsideFocusTrap && this.disposeClickHandler) {
            this.disposeClickHandler();
            this.disposeClickHandler = null;
        }
    }

    @Watch('disabled')
    protected onDisabledChanged(): void {
        if (!this.disabled) {
            this.bringFocusIntoZone();
        }
    }

    protected mounted(): void {
        this.bringFocusIntoZone();
        this.updateEventHandlers();
    }

    protected beforeDestroy(): void {
        if (this.disposeFocusHandler) {
            this.disposeFocusHandler();
            this.disposeFocusHandler = null;
        }

        if (this.disposeClickHandler) {
            this.disposeClickHandler();
            this.disposeClickHandler = null;
        }
    }
}
