type EventMap = HTMLElementEventMap & WindowEventMap;
export function on<K extends keyof EventMap>(
    element: HTMLElement | Window,
    eventName: K,
    callback: (this: HTMLElement | Window, ev: EventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
) {
    element.addEventListener(eventName, callback as any, options);

    return () => element.removeEventListener(eventName, callback as any, options);
}
