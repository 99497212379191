import moment from 'moment';
import { RouteConfig } from 'vue-router';

import { Comidas_Acceso, Comidas_Admin } from '../auth/permissions';

const CocinaMain = (r: any) => import(/* webpackChunkName: "cocina" */ './main/index.vue').then(r);
const CocinaSummary = (r: any) => import(/* webpackChunkName: "cocina" */ './cocina-summary/index.vue').then(r);
const CentroSummary = (r: any) => import(/* webpackChunkName: "cocina" */ './centro-summary/index.vue').then(r);
const IngestaSummary = (r: any) => import(/* webpackChunkName: "cocina" */ './ingesta-summary/index.vue').then(r);
const ComidasList = (r: any) => import(/* webpackChunkName: "cocina-comidas-list" */ './comidas/index.vue').then(r);

function parseDate(queryParam: string | Array<string | null>) {
    if (typeof queryParam === 'string') {
        const date = moment(queryParam, 'YYYY-MM-DD');

        if (date.isValid()) {
            return date.startOf('day');
        }
    }

    return moment().startOf('day');
}

function parseIngesta(ingestaId: string) {
    if (!ingestaId) {
        return undefined;
    }

    const id = Number.parseInt(ingestaId, 10);

    if (Number.isInteger(id)) {
        return id;
    }

    return undefined;
}

export default {
    path: 'cocina',
    component: CocinaMain,
    meta: {
        permissions: [Comidas_Acceso, Comidas_Admin],
        anyPermissionValid: true,
        title: 'cocina',
    },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "cocina" */ './store');
        next();
    },
    props: (route) => ({
        ...route.params,
        date: parseDate(route.query.date),
        ingestaId: parseIngesta(route.params.ingestaId),
        seleccion: route.query.seleccion ? parseInt(route.query.seleccion as string, 10) : undefined,
        filter: route.query.filter,
    }),
    children: [
        {
            path: '',
            name: 'home.cocina.cocina-summary',
            component: CocinaSummary,
            props: (route) => ({
                ...route.params,
                selectedDate: parseDate(route.query.date),
            }),
        },
        {
            path: 'centros/:centroId',
            name: 'home.cocina.centro-summary',
            component: CentroSummary,
            props: (route) => ({
                ...route.params,
                selectedDate: parseDate(route.query.date),
            }),
        },
        {
            path: 'centros/:centroId/ingestas/:ingestaId',
            name: 'home.cocina.ingesta-summary',
            component: IngestaSummary,
            props: (route) => ({
                ...route.params,
                ingestaId: parseInt(route.params.ingestaId, 10),
                selectedDate: parseDate(route.query.date),
            }),
        },
        {
            path: 'centros/:centroId/ingestas/:ingestaId/comidas',
            name: 'home.cocina.comidas-ingesta',
            component: ComidasList,
            props: (route) => ({
                ...route.params,
                ingestaId: parseInt(route.params.ingestaId, 10),
                date: parseDate(route.query.date),
                seleccion: route.query.seleccion ? parseInt(route.query.seleccion as string, 10) : undefined,
            }),
        },
        {
            path: 'centros/:centroId/ingestas/:ingestaId/servicios/:servicioId/comidas',
            name: 'home.cocina.comidas-servicio',
            component: ComidasList,
            props: (route) => ({
                ...route.params,
                ingestaId: parseInt(route.params.ingestaId, 10),
                date: parseDate(route.query.date),
                seleccion: route.query.seleccion ? parseInt(route.query.seleccion as string, 10) : undefined,
            }),
        },
    ],
} as RouteConfig;
