import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Platos_Acceso, Platos_Admin } from '@/auth/permissions';

const PlatosList = () => import(/* webpackChunkName: "platos" */ './list/index.vue');
const AddPlato = () => import(/* webpackChunkName: "add-plato" */ './add-plato/index.vue');
const PlatoDetail = () => import(/* webpackChunkName: "platos" */ './detail/index.vue');

export default {
    path: 'platos',
    name: 'home.platos',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.platos.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "platos" */ './store');
        next();
    },
    meta: {
        permissions: [Platos_Acceso, Platos_Admin],
        anyPermissionValid: true,
        title: 'platos',
    },
    children: [
        {
            path: '',
            name: 'home.platos.list',
            component: PlatosList,
        },
        {
            path: 'add',
            name: 'home.platos.add',
            component: AddPlato,
            meta: {
                permissions: [Platos_Admin],
                anyPermissionValid: false,
            },
        },
        {
            path: ':platoId',
            name: 'home.platos.detail',
            component: PlatoDetail,
        },
    ],
} as RouteConfig;
