import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Dietas_Acceso, Dietas_Admin } from '@/auth/permissions';

const DietasList = () => import(/* webpackChunkName: "dietas" */ './list/index.vue');
const DietaDetail = () => import(/* webpackChunkName: "dietas" */ './detail/index.vue');
const MotivosCambioList = () => import(/* webpackChunkName: "dietas-motivos-cambio" */ './motivos-cambio/index.vue');

export default {
    path: 'dietas',
    name: 'home.dietas',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.dietas.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "dietas" */ './store');
        next();
    },
    meta: {
        permissions: [Dietas_Acceso, Dietas_Admin],
        anyPermissionValid: true,
        title: 'dietas',
    },
    children: [
        {
            path: '',
            name: 'home.dietas.list',
            component: DietasList,
        },
        {
            path: 'motivos-cambio',
            name: 'home.dietas.motivos-cambio',
            component: MotivosCambioList,
        },
        {
            path: ':dietaId',
            name: 'home.dietas.detail',
            component: DietaDetail,
        },
    ],
} as RouteConfig;
