import axios from 'axios';
import { Moment } from 'moment';

import { Ingesta } from '../../ingestas/models/ingesta';
import { Dieta } from '../models/dieta';
import { Exclusion } from '@/exclusiones/models/exclusion';
import { Preparacion } from '@/preparaciones/models/preparacion';
import { ModoServicioComida } from '../models/modo-servicio-comida';

export async function getDietas(
    {
        centroId,
        servicioId,
        fecha,
        filtro,
        soloActivos = true,
    }: {
        centroId?: string;
        servicioId?: string;
        fecha?: Moment;
        filtro?: string;
        soloActivos?: boolean;
    } = {},
    signal?: AbortSignal,
) {
    const response = await axios.get('dietas', {
        params: {
            centroId,
            servicioId,
            fecha: fecha ? fecha.format('YYYY-MM-DD') : undefined,
            filtro,
            soloActivos,
        },
        signal,
    });

    if (!Array.isArray(response.data.dietas)) {
        throw new Error('Expected Array');
    }

    const ingestas = response.data.linked.ingestas.map((i: any) => new Ingesta(i)) as Ingesta[];

    return response.data.dietas.map(
        (d: any) =>
            new Dieta({
                ...d,
                ingestas: ingestas.filter((i) => (d.ingestaIds || []).includes(i.id)),
            }),
    ) as Dieta[];
}

export async function getDieta(dietaId: number) {
    const response = await axios.get(`dietas/${dietaId}`);

    return new Dieta({
        ...response.data.dieta,
        exclusiones: [...response.data.dieta.exclusiones].map((e) => new Exclusion(e)),
        preparaciones: [...response.data.dieta.preparaciones].map((e) => new Preparacion(e)),
    });
}

export async function updateDieta(
    dietaId: number,
    {
        nombre,
        nombreCorto,
        codigo,
        observaciones,
        description,
        maxDias,
        modoServicio,
        admiteSeleccion,
        limitarSeleccion,
        isDeleted,
        exclusionIds,
        preparacionIds,
    }: {
        nombre?: string;
        nombreCorto?: string;
        codigo?: string;
        observaciones?: string;
        description?: string | null;
        maxDias?: number | null;
        modoServicio?: ModoServicioComida;
        admiteSeleccion?: boolean;
        limitarSeleccion?: boolean;
        isDeleted?: boolean;
        exclusionIds?: number[];
        preparacionIds?: number[];
    },
) {
    await axios.patch(`dietas/${dietaId}`, {
        nombre,
        nombreCorto,
        codigo,
        observaciones,
        description,
        maxDias,
        modoServicio,
        admiteSeleccion,
        limitarSeleccion,
        isDeleted,
        exclusionIds,
        preparacionIds,
    });
}

export async function createDieta({
    nombre,
    nombreCorto,
    codigo,
    observaciones,
    description,
    maxDias,
    modoServicio,
    admiteSeleccion,
    limitarSeleccion,
    exclusionIds,
    preparacionIds,
    recetasTemplateId,
}: {
    nombre: string;
    nombreCorto?: string;
    codigo?: string;
    observaciones?: string;
    description?: string | null;
    maxDias: number | null;
    modoServicio: ModoServicioComida;
    admiteSeleccion: boolean;
    limitarSeleccion: boolean;
    exclusionIds: number[];
    preparacionIds: number[];
    recetasTemplateId?: number;
}) {
    await axios.post('dietas', {
        nombre,
        nombreCorto,
        codigo,
        observaciones,
        description,
        maxDias,
        modoServicio,
        admiteSeleccion,
        limitarSeleccion,
        exclusionIds,
        preparacionIds,
        recetasTemplateId,
    });
}
