export class MotivoCambioDieta {
    public id: number;
    public nombre: string;
    public isDeleted: boolean;

    public constructor(data: MotivoCambioDieta = {} as any) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.isDeleted = data.isDeleted;
    }
}
