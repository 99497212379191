import Vue, { PropType } from 'vue';
import { UnidadMedida } from '@/unidades-medida/models/unidad-medida';

export const UnidadMedidaAbbr = Vue.extend({
    name: 'UnidadMedidaAbbr',
    functional: true,
    props: {
        unidadMedida: { type: [UnidadMedida, Object] as PropType<UnidadMedida>, required: true },
    },
    render(h, ctx) {
        return ctx.props.unidadMedida.symbol ? (
            <abbr {...ctx.data} title={ctx.props.unidadMedida.nombre}>
                {ctx.props.unidadMedida.symbol}
            </abbr>
        ) : (
            <span {...ctx.data}>{ctx.props.unidadMedida.nombre}</span>
        );
    },
});
