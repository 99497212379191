import { h } from 'vue';
import { RouteConfig, Route } from 'vue-router';
import moment from 'moment';

import { Reposiciones_Acceso, Reposiciones_Solicitud, Reposiciones_Admin } from '../auth/permissions';

// tslint:disable:whitespace
const ReposicionesList = (r: any) => import(/* webpackChunkName: "reposiciones" */ './list/index.vue').then(r);
const ReposicionDetail = (r: any) => import(/* webpackChunkName: "reposiciones" */ './detail/index.vue').then(r);
const SalidasAlmacen = (r: any) => import(/* webpackChunkName: "salidas-almacen" */ './salidas-almacen/index.vue').then(r);
// tslint:enable:whitespace

export default {
    path: 'centros/:centroId/reposiciones',
    name: 'home.reposiciones',
    redirect: { name: 'home.reposiciones.list' },
    component: {
        render: () => h('router-view'),
    },
    meta: {
        permissions: [Reposiciones_Acceso, Reposiciones_Solicitud, Reposiciones_Admin],
        anyPermissionValid: true,
        title: 'reposiciones',
    },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "reposiciones" */ './store');
        next();
    },
    children: [
        {
            path: '',
            name: 'home.reposiciones.list',
            component: ReposicionesList,
            props: (route: Route) => ({
                ...route.params,
                servicioId: route.query.servicioId ? route.query.servicioId : undefined,
                date: route.query.fecha ? moment(route.query.fecha as string, 'YYYY-MM-DD') : moment().startOf('day'),
                pendientes: route.query.pendientes === 'true',
                conSolicitud: route.query.conSolicitud === 'true',
            }),
        },
        {
            path: 'salidas-almacen',
            name: 'home.reposiciones.salidas-almacen',
            component: SalidasAlmacen,
            props: (route: Route) => ({
                ...route.params,
                servicioId: route.query.servicioId ? route.query.servicioId : undefined,
                date: route.query.fecha ? moment(route.query.fecha as string, 'YYYY-MM-DD') : moment().startOf('day').format('YYYY-MM-DD'),
                pendientes: route.query.pendientes === 'true',
                conSolicitud: route.query.conSolicitud === 'true',
            }),
        },
        {
            path: ':reposicionId',
            name: 'home.reposiciones.detail',
            component: ReposicionDetail,
            props: (route: Route) => ({
                ...route.params,
                reposicionId: parseInt(route.params.reposicionId, 10),
            }),
        },
    ],
} as RouteConfig;
