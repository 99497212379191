import axios from 'axios';
import { ActionTree } from 'vuex';

import { CoquusState } from './';
import {
    LoadExclusiones,
    LoadPreparaciones,
    LoadCultures,
    LoadMotivosCambioDieta,
    LoadUnidadesMedida,
    LoadUnidadesMatriz,
    LoadFuentes,
    LoadDietas,
    LoadIngestas,
    LoadTemporadas,
    LoadTipoPlatos,
    LoadComplejos,
    ChangeUserLocale,
    LoadNutrientGrids,
} from './action-payloads';
import { Exclusion } from '../exclusiones/models/exclusion';
import {
    SetExclusiones,
    SetPreparaciones,
    SetCultures,
    SetMotivosCambioDieta,
    SetUnidadesMedida,
    SetUnidadesMedidaLoading,
    SetUnidadesMatriz,
    SetFuentes,
    SetDietas,
    SetIngestas,
    SetTemporadas,
    SetTipoPlatos,
    SetComplejos,
    SetUserLocale,
    SetNutrientGrids,
    SetNutrientGridsLoading,
} from './mutation-payloads';
import { Preparacion } from '../preparaciones/models/preparacion';
import { Culture } from '../langs/models/culture';
import { MotivoCambioDieta } from '../dietas/models/motivo-cambio-dieta';
import { UnidadMedida } from '@/unidades-medida/models/unidad-medida';
import { UnidadesMedidaInclude } from '@/unidades-medida/service/unidades-medida';
import { UnidadMatriz, Fuente } from '@/articulos/models/alimento';
import { getDietas } from '@/dietas/service/dieta';
import { getIngestas } from '@/ingestas/service/ingestas';
import { getTemporadas } from '@/temporadas/service/temporadas';
import { getTipoPlatos } from '@/tipo-platos/service/tipo-platos';
import { getComplejos } from '@/complejos/service/complejos';

/* eslint-disable @typescript-eslint/no-unused-vars */

export default {
    async loadExclusiones({ commit, state }, payload: LoadExclusiones) {
        if (state.cache.exclusiones.length > 0) {
            return;
        }

        const response = await axios.get('/exclusiones', { params: { soloActivos: false } });
        const exclusiones = response.data.exclusiones.map((e: any) => new Exclusion(e));
        commit(new SetExclusiones(exclusiones));
    },
    async loadPreparaciones({ commit, state }, payload: LoadPreparaciones) {
        if (state.cache.preparaciones.length > 0) {
            return;
        }

        const response = await axios.get('/preparaciones', { params: { soloActivos: false } });
        const preparaciones = response.data.preparaciones.map((p: any) => new Preparacion(p));
        commit(new SetPreparaciones(preparaciones));
    },
    async loadCultures({ commit, state }, payload: LoadCultures) {
        if (state.cache.cultures.length > 0) {
            return;
        }

        const response = await axios.get('/cultures');
        const cultures = response.data.cultures.map((c: any) => new Culture(c));
        commit(new SetCultures(cultures));
    },
    async loadMotivosCambioDieta({ commit, state }, payload: LoadMotivosCambioDieta) {
        if (state.cache.motivosCambioDieta.length > 0) {
            return;
        }

        const response = await axios.get('/dietas/motivos-cambio', { params: { deleted: false } });
        const motivosCambioDieta = response.data.motivos.map((m: any) => new MotivoCambioDieta(m));
        commit(new SetMotivosCambioDieta(motivosCambioDieta));
    },
    async loadUnidadesMedida({ commit, state }, payload: LoadUnidadesMedida) {
        if (state.cache.unidadesMedida.length > 0) {
            return;
        }

        if (state.cache.unidadesMedidaLoading) {
            return;
        }

        try {
            commit(new SetUnidadesMedidaLoading(true));

            const response = await axios.get('/unidades-medida', { params: { soloActivos: false, include: [UnidadesMedidaInclude.Conversiones] } });
            const unidadesMedida = response.data.unidadesMedida.map((u: any) => new UnidadMedida(u));
            commit(new SetUnidadesMedida(unidadesMedida));
        } finally {
            commit(new SetUnidadesMedidaLoading(false));
        }
    },
    async loadUnidadesMatriz({ commit, state }, payload: LoadUnidadesMatriz) {
        if (state.cache.unidadesMatriz.length > 0) {
            return;
        }

        const response = await axios.get('/uds-matriz', { params: { soloActivos: false } });
        const unidadesMedida = response.data.linked.unidadesMedida.map((u: any) => new UnidadMedida(u)) as UnidadMedida[];
        const unidadesMatriz = response.data.unidadesMatriz.map(
            (u: any) =>
                new UnidadMatriz({
                    ...u,
                    unidadMedida: unidadesMedida.find((um) => um.id === u.unidadMedidaId),
                }),
        ) as UnidadMatriz[];

        commit(new SetUnidadesMatriz(unidadesMatriz));
    },
    async loadFuentes({ commit, state }, payload: LoadFuentes) {
        if (state.cache.fuentes.length > 0) {
            return;
        }

        const response = await axios.get('/alimentos/fuentes');
        const fuentes = response.data.fuentes.map((f: any) => new Fuente(f)) as Fuente[];
        commit(new SetFuentes(fuentes));
    },
    async loadDietas({ commit, state }, payload: LoadDietas) {
        if (state.cache.dietas.length > 0) {
            return;
        }

        const dietas = await getDietas({ soloActivos: false });
        commit(new SetDietas(dietas));
    },
    async loadIngestas({ commit, state }, payload: LoadIngestas) {
        if (state.cache.ingestas.length > 0) {
            return;
        }

        const ingestas = await getIngestas({ soloActivos: false });
        commit(new SetIngestas(ingestas));
    },
    async loadTemporadas({ commit, state }, payload: LoadTemporadas) {
        if (state.cache.temporadas.length > 0) {
            return;
        }

        const temporadas = await getTemporadas({ soloActivos: false });
        commit(new SetTemporadas(temporadas));
    },
    async loadTipoPlatos({ commit, state }, payload: LoadTipoPlatos) {
        if (state.cache.tipoPlatos.length > 0) {
            return;
        }

        const tipoPlatos = await getTipoPlatos({ soloActivos: false });
        commit(new SetTipoPlatos(tipoPlatos));
    },
    async loadComplejos({ commit, state }, payload: LoadComplejos) {
        if (state.cache.complejos.length > 0) {
            return;
        }

        const complejos = await getComplejos();
        commit(new SetComplejos(complejos));
    },
    async changeUserLocale({ commit, state }, payload: ChangeUserLocale) {
        if (state.loggedUser?.locale !== payload.locale) {
            await axios.put(`/me/locale`, { locale: payload.locale });
            commit(new SetUserLocale(payload.locale));
        }
    },
    async loadNutrientGrids({ commit, state }, payload: LoadNutrientGrids) {
        if (state.cache.nutrientGrids.length > 0) {
            return;
        }

        if (state.cache.nutrientGridsLoading) {
            return;
        }

        try {
            commit(new SetNutrientGridsLoading(true));
            const response = await axios.get<{ id: number; name: string }[]>('/nutrients/grids');
            commit(new SetNutrientGrids(response.data));
        } finally {
            commit(new SetNutrientGridsLoading(false));
        }
    },
} as ActionTree<CoquusState, CoquusState>;
