<template>
    <span ref="spanEl" class="truncate text-ellipsis" :title="title">{{ text }}</span>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, ref, watch } from 'vue';

export default defineComponent({
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const spanEl = ref<HTMLSpanElement>();

        const isTextTruncated = ref(false);

        let observer: ResizeObserver | undefined = undefined;
        // Check if ResizeObserver is supported
        if ('ResizeObserver' in window) {
            observer = new ResizeObserver((entries) => {
                const target = entries[0].target as HTMLSpanElement;
                isTextTruncated.value = target.offsetWidth < target.scrollWidth;
            });
        }

        onUnmounted(() => {
            observer?.disconnect();
        });

        watch([spanEl, () => props.text], ([newEl], [oldEl]) => {
            if (!observer) {
                return;
            }

            if (oldEl) {
                observer.unobserve(oldEl);
            }

            if (newEl) {
                observer.observe(newEl);
            }
        });

        const title = computed(() => (isTextTruncated.value ? props.text : undefined));

        return {
            spanEl,
            title,
        };
    },
});
</script>

<style scoped lang="scss">
.text-ellipsis {
    display: inline-block !important;
    vertical-align: bottom;
    max-width: 100%;
}
</style>
