import axios from 'axios';
import store from '../store';
import { signin } from './user-manager';
import { HttpStatusCode } from '../http';

axios.interceptors.request.use(
    (config) => {
        const user = store.state.loggedUser;

        if (user && !user.expired) {
            if (config.headers) {
                config.headers.Authorization = user.authorizationHeader;
            } else {
                config.headers = {
                    Authorization: user.authorizationHeader,
                };
            }
        }

        return config;
    },
    (error) => Promise.reject(error),
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (axios.isAxiosError(error) && error.response && error.response.status === HttpStatusCode.Unauthorized) {
            signin();
            return Promise.reject(error);
        }

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    },
);

(parent as any).axios = axios;
