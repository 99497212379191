import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Cocinas_Acceso, Cocinas_Admin } from '@/auth/permissions';

const CocinasList = () => import(/* webpackChunkName: "cocinas" */ './list/index.vue');
const CocinaDetail = () => import(/* webpackChunkName: "cocinas" */ './detail/index.vue');

export default {
    path: 'cocinas',
    name: 'home.cocinas',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.cocinas.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "cocinas" */ './store');
        next();
    },
    meta: {
        permissions: [Cocinas_Acceso, Cocinas_Admin],
        anyPermissionValid: true,
        title: 'cocinas',
    },
    children: [
        {
            path: '',
            name: 'home.cocinas.list',
            component: CocinasList,
        },
        {
            path: ':cocinaId',
            name: 'home.cocinas.detail',
            component: CocinaDetail,
            async beforeEnter(to, from, next) {
                await import(/* webpackChunkName: "cocinas" */ './detail/materials-storage-settings/store');
                next();
            },
        },
    ],
} as RouteConfig;
