export interface IngestaData {
    id: number;
    nombre: string;
    horaCierre?: number;
    horaServicio?: number;
    isDeleted?: boolean;
}

export class Ingesta {
    public id: number;
    public nombre: string;
    public horaCierre?: number;
    public horaServicio?: number;
    public isDeleted: boolean;

    public constructor(data: IngestaData = {} as IngestaData) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.horaCierre = data.horaCierre;
        this.horaServicio = data.horaServicio;
        this.isDeleted = data.isDeleted !== undefined ? data.isDeleted : false;
    }
}
