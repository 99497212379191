import { h } from 'vue';
import { RouteConfig } from 'vue-router';

import { Menus_Acceso, Menus_Admin } from '@/auth/permissions';

const MenuPlanList = () => import(/* webpackChunkName: "menu-plan-list" */ './list/index.vue');
const MenuPlanDetail = () => import(/* webpackChunkName: "menu-plan-detail" */ './detail/index.vue');

export default {
    path: 'menu-plans',
    name: 'home.menu-plans',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.menu-plans.list' },
    meta: {
        permissions: [Menus_Acceso, Menus_Admin],
        anyPermissionValid: true,
        title: 'tabTitles.menuPlans.list',
    },
    children: [
        {
            path: '',
            name: 'home.menu-plans.list',
            component: MenuPlanList,
        },
        {
            path: ':menuPlanId',
            name: 'home.menu-plans.detail',
            props: (r) => ({ ...r.params, menuPlanId: Number.parseInt(r.params.menuPlanId, 10) }),
            component: MenuPlanDetail,
        },
    ],
} as RouteConfig;
