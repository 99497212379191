import { MutationTree } from 'vuex';
import {
    SetUser,
    SetExclusiones,
    SetPreparaciones,
    SetCultures,
    SetMotivosCambioDieta,
    SetUnidadesMedida,
    SetUnidadesMedidaLoading,
    SetUnidadesMatriz,
    SetFuentes,
    SetDietas,
    SetIngestas,
    SetTemporadas,
    SetTipoPlatos,
    SetComplejos,
    AddCentroToLoggedUser,
    AddServicioToLoggedUser,
    SetUserLocale,
    UpdateLoggedUserCentro,
    UpdateLoggedUserServicio,
    SetNutrientGrids,
    SetNutrientGridsLoading,
} from './mutation-payloads';
import { CoquusState } from './';

export default {
    setUser(state, payload: SetUser) {
        state.loggedUser = payload.user;
    },
    setExclusiones(state, payload: SetExclusiones) {
        state.cache.exclusiones = payload.exclusiones;
    },
    setPreparaciones(state, payload: SetPreparaciones) {
        state.cache.preparaciones = payload.preparaciones;
    },
    setCultures(state, payload: SetCultures) {
        state.cache.cultures = payload.cultures;
    },
    setMotivosCambioDieta(state, payload: SetMotivosCambioDieta) {
        state.cache.motivosCambioDieta = payload.motivosCambioDieta;
    },
    setUnidadesMedida(state, payload: SetUnidadesMedida) {
        state.cache.unidadesMedida = payload.unidadesMedida;
    },
    setUnidadesMedidaLoading(state, payload: SetUnidadesMedidaLoading) {
        state.cache.unidadesMedidaLoading = payload.value;
    },
    setUnidadesMatriz(state, payload: SetUnidadesMatriz) {
        state.cache.unidadesMatriz = payload.unidadesMatriz;
    },
    setFuentes(state, payload: SetFuentes) {
        state.cache.fuentes = payload.fuentes;
    },
    setDietas(state, payload: SetDietas) {
        state.cache.dietas = payload.dietas;
    },
    setIngestas(state, payload: SetIngestas) {
        state.cache.ingestas = payload.ingestas;
    },
    setTemporadas(state, payload: SetTemporadas) {
        state.cache.temporadas = payload.temporadas;
    },
    setTipoPlatos(state, payload: SetTipoPlatos) {
        state.cache.tipoPlatos = payload.tipoPlatos;
    },
    setComplejos(state, payload: SetComplejos) {
        state.cache.complejos = payload.complejos;
    },
    addCentroToLoggedUser(state, payload: AddCentroToLoggedUser) {
        if (state.loggedUser) {
            if (state.loggedUser.centros.findIndex((c) => c.id === payload.centro.id) === -1) {
                state.loggedUser.centros.push(payload.centro);
            }
        }
    },
    addServicioToLoggedUser(state, payload: AddServicioToLoggedUser) {
        if (state.loggedUser) {
            const centroIndex = state.loggedUser.centros.findIndex((c) => c.id === payload.servicio.centroId);
            if (centroIndex !== -1) {
                if (state.loggedUser.centros[centroIndex].servicios.findIndex((s) => s.id === payload.servicio.id) === -1) {
                    state.loggedUser.centros[centroIndex].servicios.push(payload.servicio);
                }
            }
        }
    },
    setUserLocale(state, payload: SetUserLocale) {
        if (state.loggedUser) {
            state.loggedUser.locale = payload.userLocale;
        }
    },
    updateLoggedUserCentro(state, payload: UpdateLoggedUserCentro) {
        if (state.loggedUser && state.loggedUser.lastLocation.diners) {
            state.loggedUser.lastLocation.diners.centroId = payload.centroId;
        }
    },
    updateLoggedUserServicio(state, payload: UpdateLoggedUserServicio) {
        if (state.loggedUser && state.loggedUser.lastLocation.diners) {
            state.loggedUser.lastLocation.diners.servicioId = payload.servicioId;
        }
    },
    setNutrientGrids(state, payload: SetNutrientGrids) {
        state.cache.nutrientGrids = payload.nutrientGrids;
    },
    setNutrientGridsLoading(state, payload: SetNutrientGridsLoading) {
        state.cache.nutrientGridsLoading = payload.value;
    },
} as MutationTree<CoquusState>;
