import Cookies from 'js-cookie';

export default class CookieStore {
    private path: string;

    constructor(path = '/') {
        this.path = path;
    }

    public getItem(key: string): string | undefined {
        return Cookies.get(key);
    }

    public setItem(key: string, value: any): void {
        Cookies.set(key, value, { path: this.path });
    }

    public removeItem(key: string): void {
        Cookies.remove(key, { path: this.path });
    }

    get length(): number {
        const cookies = Cookies.get();

        return Object.getOwnPropertyNames(cookies).length;
    }

    public key(index: number): string {
        const cookies = Cookies.get();

        return Object.getOwnPropertyNames(cookies)[index];
    }
}
