import { Conversion } from '@/articulos/models/conversion';

interface UnidadMedidaData {
    id: number;
    nombre: string;
    codigo: string;
    symbol?: string;
    isDeleted?: boolean;
    conversiones?: Conversion[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UnidadMedidaConversionData {
    unidadMedida: UnidadMedida;
    factorConversion: number;
}

export class UnidadMedida {
    public id: number;
    public nombre: string;
    public codigo: string;
    public symbol?: string;
    public isDeleted: boolean;
    public conversiones: Conversion[];

    public constructor(data: UnidadMedidaData = {} as UnidadMedidaData) {
        this.id = data.id;
        this.nombre = data.nombre;
        this.codigo = data.codigo;
        this.symbol = data.symbol || '';
        this.isDeleted = data.isDeleted || false;
        this.conversiones = data.conversiones ? data.conversiones.map((c) => new Conversion(c)) : [];
    }
}
