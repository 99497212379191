import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import i18n from './i18n';
import { getLocales } from './i18n/locale';
import store from './store';
import _ from 'lodash';

axios.defaults.baseURL = parent.coquus.apiUri;
// En el servidor se comprueba esta cabecera para deshabilitar la caché
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });

function createAcceptLanguageHeader(languages: string[]) {
    // Obtener los primeros 10 idiomas no duplicados
    return _.take(
        languages.filter((value, index, self) => self.indexOf(value) === index),
        10,
    )
        .map((l, idx) => {
            const q = _.round(1 - idx * 0.1, 1);
            if (q === 1) {
                return l;
            }
            return `${l};q=${q}`;
        })
        .join(',');
}

axios.interceptors.request.use((config) => {
    if (config.headers) {
        if (store.state.loggedUser?.locale) {
            config.headers['Accept-Language'] = createAcceptLanguageHeader([store.state.loggedUser.locale, ...getLocales()]);
        } else {
            config.headers['Accept-Language'] = createAcceptLanguageHeader(getLocales());
        }
    }
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error: unknown) => {
        const reject = Promise.reject(error);

        if (axios.isCancel(error)) {
            return reject;
        }

        if (!axios.isAxiosError(error)) {
            return reject;
        }

        if ((error.config as any).ignoreNotifications) {
            return reject;
        }

        if (error.response && error.response.status === HttpStatusCode.Forbidden) {
            let msg: string | null = null;

            if (error.response.data && error.response.data.title) {
                msg = error.response.data.title;
            }

            toastr.warning(msg || (i18n.t('forbidden') as string));

            return reject;
        }

        if (error.response && error.response.status >= HttpStatusCode.InternalServerError) {
            if (error.response.data && error.response.data.title) {
                toastr.warning(error.response.data.title);
                return reject;
            }

            toastr.error(i18n.t('serverError') as string);

            return reject;
        }

        if (error.response && error.response.data) {
            if (error.response.data.parameters) {
                for (const param of error.response.data.parameters) {
                    toastr.warning(param.reason, param.name);
                }

                return reject;
            }

            if (error.response.data.detail) {
                toastr.warning(error.response.data.detail);

                return reject;
            }

            if (error.response.data.title) {
                toastr.warning('', error.response.data.title);

                return reject;
            }
        }

        return reject;
    },
);

export enum HttpStatusCode {
    Continue = 100,
    SwitchingProtocols = 101,
    Processing = 102,
    Checkpoint = 103,
    OK = 200,
    Created = 201,
    Accepted = 202,
    NonAuthoritativeInformation = 203,
    NoContent = 204,
    ResetContent = 205,
    PartialContent = 206,
    MultipleStatus = 207,
    IMUsed = 226,
    MultipleChoices = 300,
    MovedPermanently = 301,
    Found = 302,
    SeeOther = 303,
    NotModified = 304,
    UseProxy = 305,
    SwitchProxy = 306,
    TemporaryRedirect = 307,
    ResumeIncomplete = 308,
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthenticationRequired = 407,
    RequestTimeout = 408,
    Conflict = 409,
    Gone = 410,
    LengthRequired = 411,
    PreconditionFailed = 412,
    RequestEntityTooLarge = 413,
    RequestUriTooLong = 414,
    UnsupportedMediaType = 415,
    RequestedRangeNotSatisfiable = 416,
    ExpectationFailed = 417,
    ImATeapot = 418,
    EnhanceYourCalm = 420,
    UnprocessableEntity = 422,
    Locked = 423,
    FailedDependency = 424,
    UnorderedCollection = 425,
    UpgradeRequired = 426,
    TooManyRequests = 429,
    NoResponse = 444,
    RetryWith = 449,
    BlockedByWindowsParentalControls = 450,
    ClientClosedRequest = 499,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    HttpVersionNotSupported = 505,
    VariantAlsoNegotiates = 506,
    InsufficientStorage = 507,
    BandwidthLimitExceeded = 509,
    NotExtended = 510,
}
