import { getCurrentInstance } from 'vue';
import VueI18n, { IVueI18n } from 'vue-i18n';

export function useI18n(): { i18n: VueI18n & IVueI18n } {
    const vm = getCurrentInstance()?.proxy;

    if (!vm) {
        throw new Error('[Coquus] @vue/composition-api not installed');
    }

    return { i18n: vm.$i18n };
}
