import { h } from 'vue';
import { RouteConfig } from 'vue-router';
import { Pactos_Acceso, Pactos_Admin } from '@/auth/permissions';

const PactosList = () => import(/* webpackChunkName: "pactos" */ './list/index.vue');
const PactoDetail = () => import(/* webpackChunkName: "pactos" */ './detail/index.vue');
const PactoAdd = () => import(/* webpackChunkName: "pactos-add" */ './add/index.vue');
const PeriodoDetail = () => import(/* webpackChunkName: "pactos" */ './periodos/detail/index.vue');
const PeriodoAdd = () => import(/* webpackChunkName: "pactos-add" */ './periodos/add/index.vue');

export default {
    path: 'centros/:centroId/pactos',
    name: 'home.pactos',
    component: { render: () => h('router-view') },
    redirect: { name: 'home.pactos.list' },
    async beforeEnter(to, from, next) {
        await import(/* webpackChunkName: "pactos" */ './store');
        next();
    },
    meta: {
        permissions: [Pactos_Acceso, Pactos_Admin],
        anyPermissionValid: true,
        title: 'pactos',
    },
    children: [
        {
            path: '',
            name: 'home.pactos.list',
            component: PactosList,
            props: true,
        },
        {
            path: 'add',
            name: 'home.pactos.add',
            component: PactoAdd,
            props: true,
        },
        {
            path: ':pactoId',
            name: 'home.pactos.detail',
            component: PactoDetail,
            props: (r) => ({
                ...r.params,
                pactoId: Number.parseInt(r.params.pactoId, 10),
            }),
        },
        {
            path: ':pactoId/periodos/add',
            name: 'home.pactos.periodos.add',
            component: PeriodoAdd,
            props: (r) => ({
                ...r.params,
                pactoId: Number.parseInt(r.params.pactoId, 10),
            }),
        },
        {
            path: ':pactoId/periodos/:periodoId',
            name: 'home.pactos.periodos.detail',
            component: PeriodoDetail,
            props: (r) => ({
                ...r.params,
                pactoId: Number.parseInt(r.params.pactoId, 10),
                periodoId: Number.parseInt(r.params.periodoId, 10),
            }),
        },
    ],
} as RouteConfig;
