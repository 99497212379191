// tslint:disable:variable-name
export const CambiosEmplatado_Acceso = 'CambiosEmplatado_Acceso';
export const Centros_Acceso = 'Centros_Acceso';
export const Centros_Admin = 'Centros_Admin';
export const Cocinas_Acceso = 'Cocinas_Acceso';
export const Cocinas_Admin = 'Cocinas_Admin';
export const Comensales_Acceso = 'Comensales_Acceso';
export const Comensales_Admin = 'Comensales_Admin';
export const Comensales_Complementos_Open = 'Comensales_Complementos_Open';
export const Comensales_LinkedComplementos_Admin = 'Comensales_LinkedComplementos_Admin';
export const Comensales_Invitados_Admin = 'Comensales_Invitados_Admin';
export const Comensales_History_Acceso = 'Comensales_History_Acceso';
export const Comensales_PerformNutrititionalScreenings = 'Comensales_PerformNutrititionalScreenings';
export const Comensales_PerformFoodIntakeControls = 'Comensales_PerformFoodIntakeControls';
export const Comidas_Acceso = 'ComidasCocina_Acceso';
export const Comidas_Admin = 'ComidasCocina_Admin';
export const Comidas_AddOpciones = 'ComidasCocina_AddOpciones';
export const ComidasAdjuntos_Acceso = 'ComidasAdjuntos_Acceso';
export const Complejos_Acceso = 'Complejos_Acceso';
export const Complejos_Admin = 'Complejos_Admin';
export const Dietas_Acceso = 'Dietas_Acceso';
export const Dietas_Admin = 'Dietas_Admin';
export const DietasCambios_Admin = 'DietasCambios_Admin';
export const DietasPuestos_Acceso = 'DietasPuestos_Acceso';
export const Estadisticas_AsignacionesExtras_Acceso = 'Estadisticas_AsignacionExtras_Acceso';
export const Estadisticas_Pactos_Acceso = 'Estadisticas_Pactos_Acceso';
export const Estadisticas_Pensiones_Acceso = 'Estadisticas_Pensiones_Acceso';
export const Estadisticas_PreciosPlatos_Acceso = 'Estadisticas_PreciosPlatos_Acceso';
export const Estadisticas_Produccion_Acceso = 'Estadisticas_Produccion_Acceso';
export const Estadisticas_Pensiones_Profiles_Admin = 'Estadisticas_Pensiones_Profiles_Admin';
export const Estadisticas_AsignacionExtra_Profiles_Admin = 'Estadisticas_AsignacionExtra_Profiles_Admin';
export const Estadisticas_Pactos_Profiles_Admin = 'Estadisticas_Pactos_Profiles_Admin';
export const Estadisticas_PreciosPlatos_Profiles_Admin = 'Estadisticas_PreciosPlatos_Profiles_Admin';
export const Estadisticas_Produccion_Profiles_Admin = 'Estadisticas_Produccion_Profiles_Admin';
export const KioskStats_Read = 'KioskStats_Read';
export const Exclusiones_Acceso = 'Exclusiones_Acceso';
export const Exclusiones_Admin = 'Exclusiones_Admin';
export const Historico_Acceso = 'ComidasHistorico_Acceso';
export const Historico_Admin = 'ComidasHistorico_Admin';
export const Ingestas_Acceso = 'Ingestas_Acceso';
export const Ingestas_Admin = 'Ingestas_Admin';
export const Langs_Acceso = 'Langs_Acceso';
export const Langs_Admin = 'Langs_Admin';
export const Menus_Acceso = 'Menus_Acceso';
export const Menus_Admin = 'Menus_Admin';
export const MotivosCambioDieta_Acceso = 'MotivosCambioDieta_Acceso';
export const MotivosCambioDieta_Admin = 'MotivosCambioDieta_Admin';
export const Nutrientes_Acceso = 'Nutrientes_Acceso';
export const Nutrientes_Admin = 'Nutrientes_Admin';
export const Pactos_Acceso = 'Pactos_Acceso';
export const Pactos_Admin = 'Pactos_Admin';
export const Platos_Acceso = 'Platos_Acceso';
export const Platos_Admin = 'Platos_Admin';
export const PreparacionesCulinarias_Acceso = 'PreparacionesCulinarias_Acceso';
export const PreparacionesCulinarias_Admin = 'PreparacionesCulinarias_Admin';
export const Reposiciones_Acceso = 'Reposiciones_Acceso';
export const Reposiciones_Admin = 'Reposiciones_Admin';
export const Reposiciones_Solicitud = 'Reposiciones_Solicitud';
export const Reposiciones_BulkComplete = 'Reposiciones_BulkComplete';
export const Roles_Acceso = 'Roles_Acceso';
export const Roles_Admin = 'Roles_Admin';
export const ServicioPeriodos_Acceso = 'ServicioPeriodos_Acceso';
export const ServicioPeriodos_Admin = 'ServicioPeriodos_Admin';
export const TarjetasSeleccion_Acceso = 'TarjetasSeleccion_Acceso';
export const TarjetasSeleccion_Admin = 'TarjetasSeleccion_Admin';
export const TiposPlatos_Acceso = 'TiposPlatos_Acceso';
export const TiposPlatos_Admin = 'TiposPlatos_Admin';
export const UnidadesMedida_Acceso = 'UnidadesMedida_Acceso';
export const UnidadesMedida_Admin = 'UnidadesMedida_Admin';
export const UnidadesProduccion_Precios_Admin = 'UnidadesProduccion_Precios_Admin';
export const UnidadesProduccion_Acceso = 'UnidadesProduccion_Acceso';
export const UnidadesProduccion_Admin = 'UnidadesProduccion_Admin';
export const Usuarios_Acceso = 'Usuarios_Acceso';
export const Usuarios_Admin = 'Usuarios_Admin';
export const Logs_Acceso = 'Logs_Acceso';
export const DietPrescription_Admin = 'DietPrescription_Admin';
export const ExternalExclusions_Override = 'ExternalExclusions_Override';
export const ExternalPreparations_Override = 'ExternalPreparations_Override';

export const Acceso_Global = 'Acceso_Global';
// tslint:enable:variable-name
