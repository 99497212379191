import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import Avatar from 'vue-avatar';
import { CoquusSideMenu } from '@coquus/side-menu';

import LoggedUser from '../auth/user';
import { openConfirmModal } from '../components/confirm-modal';
import { CoquusState } from '../store/index';

import '@coquus/side-menu/dist/coquus-side-menu.css';

import {
    Centros_Admin,
    Cocinas_Admin,
    Comensales_Acceso,
    Comensales_Admin,
    Comensales_Invitados_Admin,
    Comidas_Acceso,
    Comidas_Admin,
    Complejos_Admin,
    Dietas_Admin,
    Estadisticas_AsignacionesExtras_Acceso,
    Estadisticas_Pactos_Acceso,
    Estadisticas_Pensiones_Acceso,
    Estadisticas_PreciosPlatos_Acceso,
    Estadisticas_Produccion_Acceso,
    Exclusiones_Admin,
    Ingestas_Admin,
    Langs_Admin,
    Menus_Acceso,
    Menus_Admin,
    Nutrientes_Admin,
    Pactos_Acceso,
    Pactos_Admin,
    Platos_Acceso,
    Platos_Admin,
    PreparacionesCulinarias_Admin,
    Reposiciones_Acceso,
    Reposiciones_Admin,
    Reposiciones_Solicitud,
    Roles_Acceso,
    Roles_Admin,
    ServicioPeriodos_Admin,
    TiposPlatos_Admin,
    UnidadesMedida_Admin,
    UnidadesProduccion_Acceso,
    UnidadesProduccion_Admin,
    Usuarios_Acceso,
    Usuarios_Admin,
    Comensales_History_Acceso,
} from '../auth/permissions';
import { PactosFilters } from './store';
import MenuDropdown from './menu-dropdown/index.vue';
import { Route } from 'vue-router';
import { Kitchen } from '@/cocinas/models/cocina';
import { changeLocale } from '@/i18n';
import { getLocales, getDefaultUserLocale } from '@/i18n/locale';
import { ChangeUserLocale } from '@/store/action-payloads';

@Component({
    components: {
        CoquusSideMenu,
        MenuDropdown,
        Avatar,
    },
    metaInfo() {
        return {
            titleTemplate: (titleChunk: string): string => {
                return titleChunk ? `${titleChunk} • ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE!;
            },
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
        };
    },
})
export default class Home extends Vue {
    public menuOpen = false;
    public isUserMenuOpen = false;
    public activeTitle: string | null = null;
    public changingLocale = false;

    @State((state: CoquusState) => state.loggedUser)
    public loggedUser: LoggedUser | null;

    @State('reposicionesFilters', { namespace: 'home' })
    private reposicionesFilters: any;
    @State('comensalesFilters', { namespace: 'home' })
    private comensalesFilters: { centroId: string | null; servicioId: string | null };
    @State('pactosFilters', { namespace: 'home' })
    private pactosFilters: PactosFilters;

    // eslint-disable-next-line @typescript-eslint/ban-types
    private removeHook: Function | null = null;

    public get selectedLocale() {
        let locale = this.loggedUser?.locale ?? '';

        // Si está en el array de locales, lo devuelve
        if (window.coquus.locales.includes(locale)) {
            return locale;
        }

        // Si tienen un guión, se lo quita y vuelve a comprobar si está en el array de locales
        locale = locale.split('-')[0];
        if (window.coquus.locales.includes(locale)) {
            return locale;
        }

        // Si el idioma obtenido del usuario no está en el array de locales, devuelve el idioma por defecto
        return getDefaultUserLocale();
    }

    public set selectedLocale(locale: string) {
        if (this.changingLocale) {
            return;
        }

        const navigatorLocales = getLocales();

        // Comprobar si hay algún idioma en el array de locales del navegador que tenga región
        const navigatorLocale = navigatorLocales.find((l) => l.includes('-') && l.split('-')[0] === locale);
        const newLocale = navigatorLocale ?? locale;

        // Se usa un bool para que mientras se cambia el locale, no se muestre nada y así, si estas en una vista
        // que tenga componente de devexpress se reinicie con el nuevo idioma seleccionado.
        // También está para que al recargar la página los componentes de devexpress estén en el idioma correcto.
        this.changingLocale = true;
        Promise.all([this.$store.dispatch(new ChangeUserLocale(newLocale)), changeLocale(newLocale)]).finally(() => (this.changingLocale = false));
    }

    public get cellaUrl(): string {
        return window.coquus.cellaUri;
    }

    public get cuscusDashboardUrl(): string {
        return window.coquus.cuscusDashboardUri;
    }

    public get selectedKitchen(): Kitchen | undefined {
        return this.loggedUser?.cocinas[0];
    }

    public get name(): string | null {
        return this.loggedUser && this.loggedUser.name;
    }

    public get userName(): string | null {
        return this.loggedUser && this.loggedUser.userName;
    }

    public get avatarUserName(): string {
        if (!this.loggedUser) {
            return '';
        }

        return this.loggedUser.name || this.loggedUser.userName;
    }

    public get canAccessComensales(): boolean {
        return (
            !!this.loggedUser &&
            this.loggedUser.canAccessAny(Comensales_Acceso, Comensales_Admin, Comensales_Invitados_Admin) &&
            !!this.loggedUser.centrosConfigurados.length
        );
    }

    public get canAccessReposiciones(): boolean {
        return (
            !!this.loggedUser &&
            this.loggedUser.canAccessAny(Reposiciones_Solicitud, Reposiciones_Acceso, Reposiciones_Admin) &&
            !!this.loggedUser.centrosConfigurados.length
        );
    }

    public get canAccessCocina(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(Comidas_Acceso, Comidas_Admin);
    }

    public get canAccessConsultas(): boolean {
        return (
            this.canAccessPensiones ||
            this.canAccessAsignacionesExtras ||
            this.canAccessPactosConsumos ||
            this.canAccessPreciosPlatos ||
            this.canAccessComensalesHistory
        );
    }

    public get canAccessPensiones(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Estadisticas_Pensiones_Acceso);
    }

    public get canAccessProduccion(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Estadisticas_Produccion_Acceso);
    }

    public get canAccessAsignacionesExtras(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Estadisticas_AsignacionesExtras_Acceso);
    }

    public get canAccessPactosConsumos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Estadisticas_Pactos_Acceso);
    }

    public get canAccessPreciosPlatos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Estadisticas_PreciosPlatos_Acceso);
    }

    public get canAccessComensalesHistory(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Comensales_History_Acceso);
    }

    public get canAccessAdministracion(): boolean {
        return this.canAccessArticulos || this.canAccessPlatos || this.canAccessMenus || this.canAccessPactos;
    }

    public get canAccessArticulos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(UnidadesProduccion_Acceso, UnidadesProduccion_Admin);
    }

    public get canAccessPlatos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(Platos_Acceso, Platos_Admin);
    }

    public get canAccessMenus(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(Menus_Acceso, Menus_Admin);
    }

    public get canAccessPactos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(Pactos_Acceso, Pactos_Admin) && !!this.loggedUser.centrosConfigurados.length;
    }

    public get canAccessConfiguracion(): boolean {
        return (
            this.canAdminComplejos ||
            this.canAdminCocinas ||
            this.canAdminCentros ||
            this.canAdminDietas ||
            this.canAdminIngestas ||
            this.canAdminTiposPlato ||
            this.canAdminExclusiones ||
            this.canAdminPreparaciones ||
            this.canAdminUnidadesMedida ||
            this.canAdminNutrientes ||
            this.canAdminPeriodos ||
            this.canAdminIdiomas
        );
    }

    public get canAccessSecurity(): boolean {
        return this.canAccessUsuarios || this.canAccessRoles;
    }

    public get canAdminComplejos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Complejos_Admin);
    }

    public get canAdminCocinas(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Cocinas_Admin);
    }

    public get canAdminCentros(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Centros_Admin);
    }

    public get canAdminDietas(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Dietas_Admin);
    }

    public get canAdminIngestas(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Ingestas_Admin);
    }

    public get canAdminTiposPlato(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(TiposPlatos_Admin);
    }

    public get canAdminExclusiones(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Exclusiones_Admin);
    }

    public get canAdminPreparaciones(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(PreparacionesCulinarias_Admin);
    }

    public get canAdminUnidadesMedida(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(UnidadesMedida_Admin);
    }

    public get canAdminNutrientes(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Nutrientes_Admin);
    }

    public get canAdminPeriodos(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(ServicioPeriodos_Admin);
    }

    public get canAdminIdiomas(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccess(Langs_Admin);
    }

    public get canAccessUsuarios(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(Usuarios_Acceso, Usuarios_Admin);
    }

    public get canAccessRoles(): boolean {
        return !!this.loggedUser && this.loggedUser.canAccessAny(Roles_Acceso, Roles_Admin);
    }

    public get comensalesParams(): any {
        if (this.loggedUser && this.canAccessComensales) {
            let centroId;
            let servicioId;

            if (this.loggedUser.lastLocation.diners) {
                centroId = this.loggedUser.lastLocation.diners.centroId;
                servicioId = this.loggedUser.lastLocation.diners.servicioId;
            } else {
                centroId = this.comensalesFilters.centroId || this.loggedUser.centrosConfigurados[0].id;
                servicioId = this.comensalesFilters.servicioId || this.loggedUser.centrosConfigurados[0].servicios[0].id;
            }

            return { centroId, servicioId };
        }

        return null;
    }

    public get reposicionesParams() {
        if (this.loggedUser && this.canAccessReposiciones) {
            const centro = this.reposicionesFilters.selectedCentro || this.loggedUser.centrosConfigurados[0];

            return {
                centroId: centro.id,
            };
        }

        return null;
    }

    public get pactosParams() {
        if (this.loggedUser && this.canAccessComensales) {
            const centroId = this.pactosFilters.centroId || this.loggedUser.centrosConfigurados[0].id;

            return { centroId };
        }

        return null;
    }

    public get inAdminCentros() {
        return this.$route.name && this.$route.name.startsWith('home.centros');
    }

    public get locales() {
        return parent.coquus.locales
            .map((l) => [l, new Intl.DisplayNames(l, { type: 'language' }).of(l)!])
            .sort(([, a], [, b]) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    }

    public get menuVisible() {
        const atComensales = this.$route.name === 'home.comensales' || this.$route.name === 'home.externoComensales';

        if (atComensales) {
            return !('embedded' in this.$route.query);
        }

        return true;
    }

    public toggleMenu() {
        this.menuOpen = !this.menuOpen;
    }

    public toggleUserMenu() {
        this.isUserMenuOpen = !this.isUserMenuOpen;
    }

    public async closeMenu() {
        if (this.menuOpen) {
            await this.$nextTick();
            this.menuOpen = false;
        }
    }

    public async closeUserMenu() {
        if (this.isUserMenuOpen) {
            await this.$nextTick();
            this.isUserMenuOpen = false;
        }
    }

    public onMenuClickOutside(e: MouseEvent) {
        if (this.menuOpen && e.target && !(this.$refs.coquusTopMenu as Element).contains(e.target as Element)) {
            this.menuOpen = false;
        }
    }

    public async logout() {
        if (!this.loggedUser) {
            return;
        }

        const result = await openConfirmModal(this, {
            title: this.$t('logOut') as string,
            message: this.$t('confirmLogoutQuestion') as string,
            size: 'tiny',
        });

        if (result) {
            this.loggedUser.logout();
        }
    }

    private afterEachRoute(to: Route) {
        if (to.meta?.title) {
            this.activeTitle = to.meta.title as string;
            return;
        }

        const parentWithTitle = [...to.matched].reverse().find((r) => !!r.meta.title);
        if (parentWithTitle) {
            this.activeTitle = parentWithTitle.meta.title as string;
            return;
        }

        this.activeTitle = null;
    }

    private created() {
        // Se hace para llamar al changeLocale con el idioma guardado en el usuario
        // eslint-disable-next-line no-self-assign
        this.selectedLocale = this.selectedLocale;

        this.removeHook = this.$router.afterEach(this.afterEachRoute);

        if (this.$route.name === 'home') {
            if (this.canAccessComensales) {
                this.$router.push({ name: 'home.comensales', params: this.comensalesParams });

                return;
            }

            if (this.canAccessReposiciones) {
                this.$router.push({ name: 'home.reposiciones.list', params: this.reposicionesParams || {} });

                return;
            }
        }
    }

    private mounted() {
        this.afterEachRoute(this.$route);
    }

    private destroyed(): void {
        if (this.removeHook) {
            this.removeHook();
        }
    }
}
