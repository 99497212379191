/**
 * Assuming the locale is in the form of
 * langtag-region (en-US)
 *
 * @param  {String} rawLocale
 * @return {String}
 */
export function formatLocale(rawLocale: string): string {
    if (typeof rawLocale !== 'string') {
        return rawLocale;
    }

    // 'en-US-u-VA-posix'.split('-').slice(0, 2)
    // ["en", "US"]
    return rawLocale
        .split('-')
        .slice(0, 2)
        .map((chunk: string, index: number) => {
            // en[0]-US[1] <- chunk[1].toUpperCase()
            if (index !== 0 && chunk.length === 2) {
                return chunk.toUpperCase();
            }

            return chunk;
        })
        .join('-');
}

export function getLocales(): string[] {
    if (window.navigator?.languages) {
        return window.navigator.languages;
    }

    if (window.navigator?.language) {
        return [window.navigator.language];
    }

    if (window.navigator?.userLanguage) {
        return [window.navigator.userLanguage];
    }

    return [];
}

export function getDefaultUserLocale(): string {
    const locales = getLocales();

    const availableLocales = window.coquus.locales;

    for (const locale of locales) {
        if (availableLocales.find((l) => l === locale || l === locale.split('-')[0])) {
            return locale;
        }
    }

    return availableLocales[0];
}
